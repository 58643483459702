window.addEventListener("turbolinks:load", () => {
    if (localStorage.getItem("carts") == null) {
        var cart = []
        localStorage.setItem("carts", JSON.stringify(cart));
    }
    if (localStorage.getItem("cart_num") == null) {

        localStorage.setItem("cart_num", JSON.stringify([]))
    }
    localStorage.setItem("cate", 'sy')



  

    if ($('.user-avatart-image').is(':visible')) {
        localStorage.setItem("cate", 'sy')
        localStorage.setItem("sortt", 'pa')
        localStorage.setItem("countt", 9)
            // remCart()
        checkObit()
        getSess()
        var count = localStorage.getItem("countt")
        var sessionId = localStorage.getItem("sess")
        var cate = localStorage.getItem("cate")
        var sorttype = localStorage.getItem("sortt")

        // getProd(cate,count,sorttype)

        if (window.location.href.indexOf("flowers") > -1) {
            getProd(cate, count, sorttype)
        }

        $('.flowe').click(function() {
            getProd(cate, count, sorttype)
        })

        if (localStorage.getItem("cart_num") != []) {
            var car = JSON.parse(localStorage.getItem("cart_num"))
        } else {
            var car = []
        }
        // console.log(car)
        var cart = []

        // console.log(typeof car)
        for (var i = 0; i < car.length; i++) {
            if (car[i].obit == getObit()) {
                cart.push(car[i])
            }
        }
        var ct = cart;
        // console.log(cart.length )
        if (ct == undefined || ct.length == 0) {
            ct = 0
        } else {
            if (ct.length > 1) {
                ct = parseInt(cart[ct.length - 1].cart);
            } else if (ct.length == 1) {
                ct = parseInt(cart[0].cart);
            }
        }
        $('.cart_count').text("(" + ct + ")")

        // custom select 
        $(function() {
            $('.price-filter').each(function(index, element) {
                $(this).parent()
                    .after()
                    .append("<div class='scrollableList pl-0'><div id='field-price' class='selectedOption creOb-input ob-input'></div><ul></ul></div>");

                $(element).each(function(idx, elm) {
                    $('option', elm).each(function(id, el) {
                        $('.scrollableList ul:last').append('<li' + ' value=' + $(el).val() + '>' + el.text + '</li>');
                    });
                    $('.scrollableList ul').hide();
                });
                var sor = localStorage.getItem("sortt")
                if (sor == 'pa') {
                    $('.scrollableList:last').children('div.selectedOption').text("Price Ascending").append("<i class='fal fa-angle-down pdown'></i>");
                } else {
                    $('.scrollableList:last').children('div.selectedOption').text("Price Descending").append("<i class='fal fa-angle-down pdown'></i>");
                }
            });

            if ($('.price-filter').is(":visible")) {

                $('.selectedOption').on('click', function() {
                    // $(this).next('ul').slideToggle(200);
                    // $('.selectedOption').not(this).next('ul').hide();
                    $('.scrollableList ul').addClass("cusselshw")
                });

                $('.scrollableList ul li').on('click', function() {
                    var selectedLI = $(this).text();
                    $(this).parent().prev('.selectedOption').text(selectedLI).append("<i class='fal fa-angle-down pdown'></i>");
                    $(this).parent('ul').hide();
                    $(this).parent('ul').removeClass("cusselshw")
                    var opt = $(this).attr('value')
                    localStorage.setItem("sorttype", opt)
                    localStorage.setItem("sortt", opt)
                    sorttype = localStorage.getItem("sorttype")
                    localStorage.setItem("countt", 9)
                    count = 9;
                    getProd(cate, count, sorttype)
                });

                $('.scrollableList').show();
                $('.price-filter').hide();
            }

        });

        //   // ADD TO CART
        $(".add-to-cart").click(function() {
            var code = window.location.href.substring(0, window.location.href.indexOf("flowers"));
            code = window.location.href.split("flowers/")[1];
            code = code.replace('#', '');
            var sessionId = localStorage.getItem("sess")
            $.ajax({
                type: 'PUT',
                url: "https://www.floristone.com/api/rest/shoppingcart?sessionid=" + sessionId + "&action=add&productcode=" + code,
                cache: false,
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('Authorization', "Basic " + cred);
                },
                success: function(response) {
                    //  console.log(response)
                    getCart(sessionId)
                    $('.flower-success').css('display', 'block')
                    setTimeout(function() {
                        $('.flower-success').css('display', 'none')
                    }, 7000);

                }
            });
        })

        // get country codes 
        $.ajax({
            type: 'GET',
            url: "https://pkgstore.datahub.io/core/country-list/data_csv/data/d7c9d7cfb42cb69f4422dec222dbbaa8/data_csv.csv",
            cache: false,
            success: function(response) {
                //  console.log(response)
                let dropdown = $('.country-select');

                dropdown.empty();

                dropdown.append('<option  disabled>Select Country *</option>');
                dropdown.prop('selectedIndex', 0);

                var frag = document.createDocumentFragment(),
                    lines = response.split('\n'),
                    option;
                for (var i = 1, len = lines.length; i < len; i++) {
                    var lastIndex = lines[i].lastIndexOf(",")

                    var s1 = lines[i].substring(0, lastIndex);
                    s1 = s1.replace('"', '')
                    s1 = s1.replace('"', '')
                    var s2 = lines[i].substring(lastIndex + 1)
                    option = document.createElement("option");
                    option.setAttribute("value", s2);
                    option.innerHTML = s1
                    frag.appendChild(option);
                    // console.log(lines[i])
                }
                dropdown.append(frag);

                let dropdownst = $('.state-select');

                dropdownst.empty();

                dropdownst.append('<option disabled>Select State *</option>');
                dropdownst.prop('selectedIndex', 0);
                var sta = []
                sta.push(

                    {
                        value: 'AK',
                        name: 'Alaska'
                    }, {
                        value: 'TX',
                        name: 'TX'
                    }, {
                        value: 'AL',
                        name: 'Alabama'
                    }, {
                        value: 'AR',
                        name: 'Arkansas'
                    }, {
                        value: 'AZ',
                        name: 'Arizona'
                    }, {
                        value: 'CA',
                        name: 'California'
                    }, {
                        value: 'CO',
                        name: 'Colorado'
                    }, {
                        value: 'CT',
                        name: 'Connecticut'
                    }, {
                        value: 'DC',
                        name: 'District of Columbia'
                    }, {
                        value: 'DE',
                        name: 'Delaware'
                    }, {
                        value: 'FL',
                        name: 'Florida'
                    }, {
                        value: 'GA',
                        name: 'Georgia'
                    }, {
                        value: 'HI',
                        name: 'Hawaii'
                    }, {
                        value: 'IA',
                        name: 'Iowa'
                    }, {
                        value: 'ID',
                        name: 'Idaho'
                    }, {
                        value: 'IL',
                        name: 'Illinois'
                    }, {
                        value: 'IN',
                        name: 'Indiana'
                    }, {
                        value: 'KS',
                        name: 'Kansas'
                    }, {
                        value: 'KY',
                        name: 'Kentucky'
                    }, {
                        value: 'LA',
                        name: 'Louisiana'
                    }, {
                        value: 'MA',
                        name: 'Massachusetts'
                    }, {
                        value: 'MD',
                        name: 'Maryland'
                    }, {
                        value: 'ME',
                        name: 'Maine'
                    }, {
                        value: 'MI',
                        name: 'Michigan'
                    }, {
                        value: 'MN',
                        name: 'Minnesota'
                    }, {
                        value: 'MO',
                        name: 'Missouri'
                    }, {
                        value: 'MS',
                        name: 'Mississippi'
                    }, {
                        value: 'MT',
                        name: 'Montana'
                    }, {
                        value: 'NC',
                        name: 'North Carolina'
                    }, {
                        value: 'ND',
                        name: 'North Dakota'
                    }, {
                        value: 'NE',
                        name: 'Nebraska'
                    }, {
                        value: 'NH',
                        name: 'New Hampshire'
                    }, {
                        value: 'NJ',
                        name: 'New Jersey'
                    }, {
                        value: 'NM',
                        name: 'New Mexico'
                    }, {
                        value: 'NV',
                        name: 'Nevada'
                    }, {
                        value: 'NY',
                        name: 'New York'
                    }, {
                        value: 'OH',
                        name: 'Ohio'
                    }, {
                        value: 'OK',
                        name: 'Oklahoma'
                    }, {
                        value: 'OR',
                        name: 'Oregon'
                    }, {
                        value: 'PA',
                        name: 'Pennsylvania'
                    }, {
                        value: 'RI',
                        name: 'RhodeIsland'
                    }, {
                        value: 'SC',
                        name: 'South Carolina'
                    }, {
                        value: 'SD',
                        name: 'South Dakota'
                    }, {
                        value: 'TN',
                        name: 'Tennessee'
                    }, {
                        value: 'TX',
                        name: 'Texas'
                    }, {
                        value: 'UT',
                        name: 'Utah'
                    }, {
                        value: 'VA',
                        name: 'Virginia'
                    }, {
                        value: 'VT',
                        name: 'Vermont'
                    }, {
                        value: 'WA',
                        name: 'Washington'
                    }, {
                        value: 'WI',
                        name: 'Wisconsin'
                    }, {
                        value: 'WV',
                        name: 'West Virginia'
                    }, {
                        value: 'WY',
                        name: 'Wyoming'
                    }

                )
                sta.sort();
                for (var x = 1; x < sta.length; x++) {
                    dropdownst.append($('<option></option>').attr('value', sta[x].value).text(sta[x].name));
                }
                $(".country-select option[value='US\r']").prop('selected', true);
            }
        });


        $(document).on("click", "i.rem-prod", function() {
            var valu = $(this).parent('div')
            valu = $(valu).attr('value')
            var sessionId = localStorage.getItem("sess")
                // console.log($(valu).attr('value'))
            $.ajax({
                type: 'PUT',
                url: "https://www.floristone.com/api/rest/shoppingcart?sessionid=" + sessionId + "&action=remove&productcode=" + valu,
                cache: false,
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('Authorization', "Basic " + cred);
                },
                success: function(response) {
                    //  console.log(response)
                    var sessionId = localStorage.getItem("sess")
                    getCart(sessionId)
                    $('.flower-success').text("Item removed from cart")
                    $('.flower-success').css('display', 'block')
                    setTimeout(function() {
                        $('.flower-success').css('display', 'none')
                    }, 7000);
                }
            });
        })

        if (window.location.href.indexOf("cart") > -1) {
            var sessionId = localStorage.getItem("sess")
            getCart(sessionId)
        }

        if (window.location.href.indexOf("billing") > -1) {
            $.ajax({
                type: 'GET',
                url: "https://www.floristone.com/api/rest/flowershop/getauthorizenetkey",
                cache: false,
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('Authorization', "Basic " + cred);
                },
                success: function(response) {
                    localStorage.setItem("auth", JSON.stringify(response))
                }
            });
        }


        // GET PRODUCTS
        // getProd(cate,count,sorttype)

        $('.more-flowers').click(function() {
            count = parseInt(localStorage.getItem("countt")) + 9
            localStorage.setItem("countt", count)
                // $("#flower-list-con").empty()
            getProd(cate, count, sorttype)
        })

        if (window.location.href.indexOf("flowers/") > -1 || window.location.href.indexOf("flowers#/") > -1) {
            var code = ""
            if (window.location.href.indexOf("flowers/") > -1) {
                code = window.location.href.split("flowers/")[1];
            } else if (window.location.href.indexOf("flowers#/") > -1) {
                code = window.location.href.split("flowers#/")[1];
            }

            // var code= "faa-100";
            $.ajax({
                type: 'GET',
                url: "https://www.floristone.com/api/rest/flowershop/getproducts?code=" + code,
                cache: false,
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('Authorization', "Basic " + cred);
                },
                success: function(response) {
                    // console.log(response)
                    for (var i = 0; i < response.PRODUCTS.length; ++i) {

                        $(".prod-img").attr("src", response.PRODUCTS[i].LARGE);
                        $(".prod-img").attr("srcset", response.PRODUCTS[i].LARGE + " 1x");
                        $(".prod-name").text(response.PRODUCTS[i].NAME)
                        $(".prod-price").text("$" + response.PRODUCTS[i].PRICE)
                        $(".prod-det").text(response.PRODUCTS[i].DESCRIPTION)

                    }
                }
            });
        }

        $(document).on("click", "a.show-flower", function() {
            var win = window.location.href.replace('#', '')
                // console.log(win)
            window.location.href = win + "/" + $(this).attr('value')
        })

        $("#zip_code_cart").on("change paste keyup click select", function() {
            if ($("#zip_code_cart").val() == "" || $("#zip_code_cart").val() == " ") {
                $(".cart-btn").attr("disabled", "disabled")
                $('.ziphide').css("display", "block")
            } else {

                if($('#zip_code_cart').val().length>=4){

                    getTotal()
                }

            }
        });
        // console.log($("#zip_code_cart").val())
        if ($('#zip_code_cart').is(":visible") &&($("#zip_code_cart").val() == "" || $("#zip_code_cart").val() == " ")) {
            $(".cart-btn").attr("disabled", "disabled")
            $('.ziphide').css("display", "block")
        } else {
            if($('#zip_code_cart').is(":visible") && $('#zip_code_cart').val().length>=4){

                getTotal()
            }
        }


        $(document).on("change paste keyup click select", "input.deli-zip", function() {
            upDates($(this))
        })

        // $(".deli-zip").on('ready', function() {
        if (window.location.href.indexOf("/delivery") > -1) {
            var car_it = localStorage.getItem("cart_it");
            car_it = JSON.parse(car_it)
                // alert(car_it)
            var x = 0;
            var carnu = 0
            if (localStorage.getItem("cart_num") != []) {
                var car = JSON.parse(localStorage.getItem("cart_num"))
            } else {
                var car = []
            }
            var cart = []
            for (var i = 0; i < car.length; i++) {
                if (car[i].obit == getObit()) {
                    cart.push(car[i])
                }
            }
            var ct = cart;
            if (ct == undefined || ct.length == 0) {
                ct = 0
            } else {
                if (ct.length > 1) {
                    ct = parseInt(cart[ct.length - 1].cart);
                } else if (ct.length == 1) {
                    ct = parseInt(cart[0].cart);
                }
            }
            carnu = ct
            if(car_it!=null){

                for (var i = 1; i < car_it.length; i++) {
                    var dev = $(".flow-deli").clone()
                    $("#flower_delivery").append(dev)
                    if (($('.flow-deli').length - 1) == car_it.length) {
                        $('.flow-deli').eq(-1).remove()
                    }
                    // $('.flow-deli')[x].children('.form-title').text(car[x].NAME)
    
                }
    
                $('.flow-deli').each(function(i, obj) {
                    $(obj).children('.form-title').text(car_it[x].NAME)
                    if ($('.flow-deli').length > car_it.length) {
                        $('.flow-deli').eq(-1).css("display", "none")
    
                    }
                    if (($('.flow-deli').length - 1) == car_it.length) {
                        $('.flow-deli').eq(-1).find("*").removeAttr("required")
                            // alert("yes")
                            // if ($('.flow-deli').eq(-1).css("display") == "none") {
                            //     $(this).find('*').removeAttr("required")
                            //     alert("yes")
                            // }
    
                    }
    
    
                    x += 1;
                });
            }

            getDates()

        };



        $('.place-order').click(function() {
                var req = checkFields()
                if (req == true) {
                    var client = localStorage.getItem("auth")
                    client = JSON.parse(client)
                    var authData = {};
                    authData.clientKey = client.AUTHORIZENET_KEY;
                    authData.apiLoginID = client.USERNAME;
                    var cardData = {};
                    cardData.cardNumber = $('.bill_det_num').val();
                    cardData.month = $('.bill_det_mm').val();
                    cardData.year = $('.bill_det_yy').val();
                    cardData.cardCode = $('.bill_det_cvv').val();


                    var secureData = {};
                    secureData.authData = authData;
                    secureData.cardData = cardData;

                    Accept.dispatchData(secureData, responseHandler);

                }
            })
            // console.log($("#subid").val())
        $(".get-delivery-opts").click(function() {
            // alert("e")
            var req = checkFields()
            if (req == true) {
                var products = [];
                var car = localStorage.getItem("cart_it");
                car = JSON.parse(car)

                $('.flow-deli').each(function(i, obj) {
                    var del_fields = $(obj).children().children().children().children().children('.delivery-fields');
                    var deli = {}
                    deli.PRICE = car[i].PRICE;
                    deli.CARDMESSAGE = $(del_fields).siblings('.deli-info').children('.deli-mess').val();
                    deli.SPECIALINSTRUCTIONS = $(del_fields).siblings('.deli-info').children('.deli-special').val();
                    deli.DELIVERYDATE = $(del_fields).siblings('.deli-info').children('.deli-date').val();
                    deli.CODE = car[i].CODE;
                    deli.RECIPIENT = {}
                    deli.RECIPIENT.ZIPCODE = $(del_fields).children('.deli-zip').val();
                    deli.RECIPIENT.PHONE = $(del_fields).children('.deli-phone').val();
                    deli.RECIPIENT.ADDRESS2 = $(del_fields).children('.deli-add2').val();
                    deli.RECIPIENT.ADDRESS1 = $(del_fields).children('.deli-add1').val();
                    deli.RECIPIENT.STATE = $(del_fields).children('.deli-st').val();
                    deli.RECIPIENT.NAME = $(del_fields).children('.deli-name').val();
                    // console.log($(del_fields).children())
                    deli.RECIPIENT.COUNTRY = $(del_fields).children('.deli-cou').val().replace('\r', '');
                    deli.RECIPIENT.INSTITUTION = $(del_fields).children('.deli-inst').val();
                    deli.RECIPIENT.CITY = $(del_fields).children('.deli-city').val();
                    // e.preventDefault()
                    if ($('.sub').children('.allFlwSub').is(":checked")) {
                        localStorage.setItem("allowsub", 1)
                    } else {
                        localStorage.setItem("allowsub", 0)
                    }
                    products.push(deli);
                })
                products = JSON.stringify(products)
                    // alert(products)
                localStorage.setItem("products", products)
                window.location.href = $(this).attr('data-value')
            }

        })

        $.get('https://www.cloudflare.com/cdn-cgi/trace', function(data) {
            var info = data.split('\n')
            for (var i = 1, len = info.length; i < len; i++) {
                info[i] = info[i].split("=")
                if (info[i][0] == "ip") {
                    localStorage.setItem("ip", info[i][1])
                }
            }
        })

        // clear local storage
        // $('.sign-out').click(function (){
        //   $("#cart-items").empty()
        //   $('.cart_count').text("(0)")
        //   localStorage.clear();
        //   signOut()
        // })
    }
    else{
        checkObit()
    }

})


function checkcart() {
    var carb = $('.cart_count').text()
    carb = carb.split('(')
    carb = carb[1].split(')')[0]
    carb = parseInt(carb)
    if (carb == 0) {
        $('.cart-btn').css('display', 'none')
        $('.del-price').text("0.00")
        $('.tax-price').text("0.00")
        $('.total-price').text("0.00")
        $('.sub-price').text("0.00")
    } else if (carb > 0) {
        $('.cart-btn').css('display', 'block')
    }
}


function responseHandler(response) {
    if (response.messages.resultCode === "Error") {
        var i = 0;
        var errs = []
        while (i < response.messages.message.length) {
            errs.push(response.messages.message[i].text)
            i = i + 1;
        }
        $('.flower-error').text(errs)
        $('.flower-error').css('display', 'block')
        setTimeout(function() {
            $('.flower-error').css('display', 'none')
        }, 7000);
    } else {
        paymentFormUpdate(response.opaqueData);
        $('.flower-error').css('display', 'none')
    }
}

function paymentFormUpdate(opaqueData) {
    placeFOrder(opaqueData.dataValue)
}

function getProd(cate, count, sorttype) {
    $.ajax({
        type: 'GET',
        url: "https://www.floristone.com/api/rest/flowershop/getproducts?category=" + cate + "&count=" + count + "&sorttype=" + sorttype,
        cache: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', "Basic " + cred);
        },
        success: function(response) {
            localStorage.setItem('titems', response.TOTAL)
            getPages()
        }
    });
}

function getCart(sessionId) {
    $.ajax({
        type: 'GET',
        url: "https://www.floristone.com/api/rest/shoppingcart?sessionid=" + sessionId,
        cache: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', "Basic " + cred);
        },
        success: function(response) {
            //  console.log(response)
            $("#cart-items").empty()
            var cart_it = []
            if(!response.errors){

                for (var i = 0; i < response.products.length; ++i) {
                    var divr = document.createElement("div");
                    var divh = document.createElement("div");
                    var divb = document.createElement("div");
                    var im = document.createElement("IMG");
                    var divw = document.createElement("div");
    
                    $(divr).addClass("cart-item-block");
                    $(divb).addClass("item-x icon");
                    $(divh).addClass("item_product-name");
                    $(divw).addClass("item_product-price");
                    divh.innerHTML = response.products[i].NAME;
                    divw.innerHTML = '$' + response.products[i].PRICE;
                    im.setAttribute("src", response.products[i].SMALL);
                    im.setAttribute("srcset", response.products[i].SMALL + " 1x");
                    $(divb).append("<i class='fal fa-times rem-prod'></i>")
                    divb.setAttribute("value", response.products[i].CODE)
                    divr.append(divb);
                    divr.append(divh);
                    divr.append(divw);
                    $("#cart-items").append(divr);
                    cart_it.push(response.products[i])
                }
                cart_it = JSON.stringify(cart_it)
                localStorage.setItem("cart_it", cart_it)
                getTotal()
    
                var carts_num = {
                    cart: response.productcount,
                    obit: getObit()
                }
                var car = JSON.parse(localStorage.getItem("cart_num"))
                car.push(carts_num)
                localStorage.setItem("cart_num", JSON.stringify(car))
                var cart = []
                for (var i = 0; i < car.length; i++) {
                    if (car[i].obit == getObit()) {
                        cart.push(car[i])
                    }
                }
                var ct = cart;
                if (ct == undefined || ct.length == 0) {
                    ct = 0
                } else {
                    if (ct.length > 1) {
                        ct = parseInt(cart[ct.length - 1].cart);
                    } else if (ct.length == 1) {
                        ct = parseInt(cart[0].cart);
                    }
                }
                $('.cart_count').text("(" + ct + ")")
                checkcart()
            }
            else{
                alert(response.errors[0])
            }
                // console.log(ct)
        }
    });
}

function delCart(sessionId, num) {
    $.ajax({
        type: 'DELETE',
        url: "https://www.floristone.com/api/rest/shoppingcart?sessionid=" + sessionId,
        cache: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', "Basic " + cred);
        },
        success: function(response) {

            remCart()
            var ref = window.location.href.split("/billing")[0];
            getCart(sessionId)

        }
    });
}



function getTotal() {
    var prod = localStorage.getItem("cart_it")
    prod = JSON.parse(prod)
        // console.log(prod)
    if(prod!=null){
        
        for (var i = 0; i < prod.length; ++i) {
            var vll = $('#zip_code_cart').val()
            prod[i]['RECIPIENT'] = {
                "ZIPCODE": vll
            }
            delete prod[i]["NAME"];
        }
        prod = JSON.stringify(prod)
        if ( prod.length > 0 && prod != undefined && prod != []) {
    
            $.ajax({
                type: 'GET',
                url: "https://www.floristone.com/api/rest/flowershop/gettotal?products=" + prod,
                cache: false,
                beforeSend: function(xhr) {
                    xhr.setRequestHeader('Authorization', "Basic " + cred);
                },
                success: function(response) {
                    // console.log(response)
                    if (response.SUBTOTAL) {
                        $(".cart-btn").attr("disabled", false)
                        $('.ziphide').css("display", "none")
                        $('.del-price').text(response.DELIVERYCHARGETOTAL)
                        $('.tax-price').text(response.TAXTOTAL)
                        $('.total-price').text(response.ORDERTOTAL)
                        $('.sub-price').text(response.SUBTOTAL.toFixed(3))
                        localStorage.setItem("ordertotal", response.ORDERTOTAL)
                    }
                    if (parseFloat(response.ORDERTOTAL) == 0 || parseFloat(response.ORDERTOTAL) == 0.00 || parseFloat(response.ORDERTOTAL) == null) {
                        setTimeout(function() {
                            getTotal()
                        }, 300);
                    }
                }
            });
        }
    }
}

// get delivery dates
function getDates() {
    var zip = $('.deli-zip').val()
    if(zip!=undefined && zip.length>=4){
        $.ajax({
            type: 'GET',
            url: "https://www.floristone.com/api/rest/flowershop/checkdeliverydate?zipcode=" + zip,
            cache: false,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', "Basic " + cred);
            },
            success: function(response) {
                // console.log(response)
                let dropdown = $('.del_select');
    
                dropdown.empty();
    
                dropdown.append('<option selected="true" disabled>Select a Date *</option>');
                dropdown.prop('selectedIndex', 0);
    
                // const url = 'https://api.myjson.com/bins/7xq2x';
    
                // Populate dropdown with list of provinces
                $.each(response.DATES, function(key, entry) {
                    dropdown.append($('<option></option>').attr('value', entry).text(entry));
                })
                // console.log($('.deli-cou').val(),"ss")
    
            }
        });
    }
}

function upDates(zi) {
    var zip = $(zi).val()
        // console.log(zip)
        if(zip!=undefined && zip.length>=4){
    $.ajax({
        type: 'GET',
        url: "https://www.floristone.com/api/rest/flowershop/checkdeliverydate?zipcode=" + zip,
        cache: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', "Basic " + cred);
        },
        success: function(response) {
            // console.log(response)
            let dropdown = $(zi).parent().siblings().children('.del_select');
            // console.log(dropdown)

            dropdown.empty();

            dropdown.append('<option selected="true" disabled>Select a Date *</option>');
            dropdown.prop('selectedIndex', 0);

            // Populate dropdown with list of provinces
            $.each(response.DATES, function(key, entry) {
                dropdown.append($('<option></option>').attr('value', entry).text(entry));
            })
            // console.log($('.deli-cou').val())

        }
    });
}
}

// place order
function placeFOrder(token) {
    var ordet = {}
    var bill_fields = $('.bill-det');

    ordet.customer = {}
    ordet.ccinfo = {}
    ordet.ccinfo.AUTHORIZENET_TOKEN = token;
    ordet.products = JSON.parse(localStorage.getItem("products"))

    ordet.ordertotal = parseFloat(localStorage.getItem("ordertotal"));
    ordet.customer.ZIPCODE = $(bill_fields).children('.bill-zip').val();
    ordet.customer.PHONE = $(bill_fields).children('.bill-phone').val();
    ordet.customer.ADDRESS2 = $(bill_fields).children('.bill-add2').val();
    ordet.customer.ADDRESS1 = $(bill_fields).children('.bill-add1').val();
    ordet.customer.STATE = $(bill_fields).children('.bill-st').val();
    ordet.customer.NAME = $(bill_fields).children('.bill-name').val();
    ordet.customer.COUNTRY = $(bill_fields).children('.bill-cou').val().replace('\r', '');
    ordet.customer.IP = localStorage.getItem("ip");
    ordet.customer.EMAIL = $(bill_fields).children('.bill-email').val();
    ordet.customer.CITY = $(bill_fields).children('.bill-city').val();
    ordet.customer = (JSON.stringify(ordet.customer))
    ordet.products = (JSON.stringify(ordet.products))
    ordet.ccinfo = (JSON.stringify(ordet.ccinfo))
        // if($("#subid").val()){
        // }
    ordet.subaffiliateid = $("#subid").val()
    ordet.allowsubstitutions = parseInt(localStorage.getItem("allowsub"))
        // console.log($("#subid").val())
    ordet = (JSON.stringify(ordet))
        // console.log( ordet)

    $.ajax({
        type: 'POST',
        url: "https://www.floristone.com/api/rest/flowershop/placeorder",
        cache: false,
        data: ordet,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', "Basic " + cred);
            xhr.setRequestHeader('Content-type', 'application/json');
        },
        success: function(response) {
            // console.log(response)

            if (response.ORDERNO) {
                getOrder(response.ORDERNO)
                $('.bill_det_num').val("");
                $('.bill_det_mm').val("");
                $('.bill_det_yy').val("");
                $('.bill_det_cvv').val("");
                $('.flower-success').text('Order has been placed')
                $('.flower-success').css('display', 'block')
                $('.flower-error').css('display', 'none')
                    // console.log(response)

            } else {
                $('.flower-success').css('display', 'none')
                $('.flower-error').text('Error submitting order' + response.errors[0])
                $('.flower-error').css('display', 'block')
            }
            setTimeout(function() {
                $('.flower-success').css('display', 'none')
            }, 7000);
            // console.log(response)
        }
    });
}


// get order details
function getOrder(num) {
    $.ajax({
        type: 'GET',
        url: "https://www.floristone.com/api/rest/flowershop/getorderinfo?orderno=" + num,
        cache: false,
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', "Basic " + cred);
        },
        success: function(response) {
            // console.log(response)
            if (response.ORDERNO == num) {
                var sessionId = localStorage.getItem("sess")
                delCart(sessionId, num)
                saveOrder(response)

            }
        }
    });
}

// save oder to database
function saveOrder(resp) {

    var or_url = $("#flowers_url").attr("data-flowers-url")
    resp = JSON.stringify(resp)
        // console.log(resp)
    $.ajax({
        type: 'POST',
        url: or_url,
        cache: false,
        data: resp,
        dataType: 'json',
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Content-type', 'application/json');
        },
        success: function(response) {
            // console.log(response)
            $('.flower-success').text(response.message)
            $('.flower-success').css('display', 'block')
            setTimeout(function() {
                $('.flower-success').css('display', 'none')
            }, 7000);
            // var ref = window.location.href.split("/billing")[0];
            window.location.href = '/floral-tributes/' + response.data.id + '/thanks'
        }
    });
}

// check if a cart exists for a obit
function checkObit() {
    var obit = getObit()
    var obses = localStorage.getItem("carts");

    if (obit != undefined && obses != null) {
        obses = JSON.parse(obses);
        if (obses.length == 0 || obses == null || obses == []) {
            creCart()
        } else {
            const found = obses.some(el => el.obit === obit);

            if (!found) {
                creCart()
            }
            for (var i = 0; i < obses.length; i++) {
                if (obses[i].obit == obit) {
                    var sessi = obses[i].sess
                    localStorage.setItem("sess", sessi)
                        // console.log(obses[i].obit,obit)
                }
            }
        }
    }
    // console.log(obses)
}

// create a cart for a particular obit
function creCart() {
    var obses = localStorage.getItem("carts");
    obses = JSON.parse(obses);
    var obit = getObit()
    $.ajax({
        type: 'POST',
        url: "https://www.floristone.com/api/rest/shoppingcart",
        cache: false,
        beforeSend: function(xhr) {
            // xhr.setRequestHeader('Authorization', "Basic " + btoa('783321' + ":" + 'lEE6FP'));
            xhr.setRequestHeader('Authorization', "Basic " + cred);
        },
        success: function(response) {
            //  console.log(response)
            var ob_sess = {
                sess: response.SESSIONID,
                obit: obit
            }

            obses.push(ob_sess)
            localStorage.setItem("carts", JSON.stringify(obses));
            getSess();
            localStorage.setItem("cate", 'sy')
            localStorage.setItem("sortt", 'pa')
            localStorage.setItem("countt", 9)

        }
    });

}

// get current obit id
function getObit() {
    var obit = window.location.href.split("obituaries/")[1];
    if (window.location.href.indexOf("/flowers") > -1) {
        obit = obit.split("/flowers")[0]
        obit = obit.split('-')[0]
        return obit
    } else if (window.location.href.indexOf("/cart") > -1) {
        obit = obit.split("/cart")[0]
        obit = obit.split('-')[0]
        return obit
    } else if (window.location.href.indexOf("/billing") > -1) {
        obit = obit.split("/billing")[0]
        obit = obit.split('-')[0]
        return obit
    } else if (window.location.href.indexOf("/delivery") > -1) {
        obit = obit.split("/delivery")[0]
        obit = obit.split('-')[0]
        return obit
    }
}

// check if fields are empty
function checkFields() {

    let allAreFilled = true;
    var fr = document.getElementById('flower_delivery');
    if (fr == undefined || fr == null) {
        fr = document.getElementById('bill-form');
    }
    var sels = []
    fr.querySelectorAll("[required]").forEach(function(i) {
        i.focus();
        if (!allAreFilled) return;
        if (!i.value) {
            allAreFilled = false;
        } else {
            allAreFilled = true;
        }
        if ($(i).val() == '') {
            allAreFilled = false;
        } else {
            allAreFilled = true;
        }
        if (i.type === "select-one") {
            if ($(i).val() == '' || $(i).val() == undefined || $(i).val() == null) {
                allAreFilled = false;
            } else {
                allAreFilled = true;
            }
        }

    })
    if (!allAreFilled) {
        $('.deli-error').css('display', 'block')
        setTimeout(function() {
            $('.deli-error').css('display', 'none')
        }, 5000);
        return false

    } else {
        return true
    }
}

// remove cart once successful order placed
function remCart() {
    var ct = (localStorage.getItem("carts"));
    var numc = (localStorage.getItem("cart_num"));
    ct = JSON.parse(ct)
    numc = JSON.parse(numc)
    for (var i = 0; i < ct.length; i++) {
        if (parseInt(ct[i].obit) == getObit()) {
            ct.splice([i], 1);
        }
    }
    for (var w = 0; w < numc.length; w++) {
        if (parseInt(numc[w].obit) == getObit()) {
            numc.splice([w], 1);
        }
    }
    localStorage.setItem("carts", JSON.stringify(ct))
    localStorage.setItem("cart_num", JSON.stringify(numc))
}

function getSess() {
    var obses = localStorage.getItem("carts");
    // console.log(obses)
    if (obses != null) {
        obses = JSON.parse(obses);
        for (var i = 0; i < obses.length; i++) {
            if (obses[i].obit == undefined) {
                obses.splice([i], 1);
            }
        }
        localStorage.setItem("carts", JSON.stringify(obses))
    }

}

// pagination
function getPageList(totalPages, page, maxLength) {
    if (maxLength < 5) throw "maxLength must be at least 5";

    function range(start, end) {
        return Array.from(Array(end - start + 1), (_, i) => i + start);
    }

    var sideWidth = maxLength < 9 ? 1 : 2;
    var leftWidth = (maxLength - sideWidth * 2 - 3) >> 1;
    var rightWidth = (maxLength - sideWidth * 2 - 2) >> 1;
    if (totalPages <= maxLength) {
        // no breaks in list
        return range(1, totalPages);
    }
    if (page <= maxLength - sideWidth - 1 - rightWidth) {
        // no break on left of page
        return range(1, maxLength - sideWidth - 1)
            .concat(0, range(totalPages - sideWidth + 1, totalPages));
    }
    if (page >= totalPages - sideWidth - 1 - rightWidth) {
        // no break on right of page
        return range(1, sideWidth)
            .concat(0, range(totalPages - sideWidth - 1 - rightWidth - leftWidth, totalPages));
    }
    // Breaks on both sides
    return range(1, sideWidth)
        .concat(0, range(page - leftWidth, page + rightWidth),
            0, range(totalPages - sideWidth + 1, totalPages));
}

// Below is an example use of the above function.
function getPages() {
    // Number of items and limits the number of items per page
    var numberOfItems = localStorage.getItem('titems')
    var limitPerPage = 9;
    // Total pages rounded upwards
    var totalPages = Math.ceil(numberOfItems / limitPerPage);
    var paginationSize = 7;
    var currentPage;

    function showPage(whichPage) {
        var sorttype = localStorage.getItem("sortt");
        var cate = 'sy';
        var count = 9;
        if (whichPage < 1 || whichPage > totalPages) return false;
        currentPage = whichPage;
        // console.log(currentPage)
        var stnum = 0;
        if (currentPage > 1) {
            stnum = (currentPage * 9) - 9 + 1;
        } else {
            stnum = 1
        }

        $.ajax({
            type: 'GET',
            url: "https://www.floristone.com/api/rest/flowershop/getproducts?category=" + cate + "&count=" + count + "&start=" + stnum + "&sorttype=" + sorttype,
            cache: false,
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', "Basic " + cred);
            },
            success: function(response) {
                localStorage.setItem('titems', response.TOTAL)

                $("#flower-list-con").empty()
                for (var i = 0; i < response.PRODUCTS.length; ++i) {
                    var divr = document.createElement("div");
                    var divh = document.createElement("div");
                    var divb = document.createElement("div");
                    var al = document.createElement("a");
                    var im = document.createElement("IMG");
                    var str = document.createElement("strong");

                    $(divr).addClass("product-con");
                    $(divb).addClass("pricing-name");
                    $(divh).addClass("pricing");
                    $(str).addClass("price");

                    str.innerHTML = "$" + response.PRODUCTS[i].PRICE;
                    divh.append(str);
                    divb.innerHTML = response.PRODUCTS[i].NAME;
                    im.setAttribute("src", response.PRODUCTS[i].SMALL);
                    im.setAttribute("srcset", response.PRODUCTS[i].SMALL + " 1x");

                    var win = window.location.href;
                    al.setAttribute("value", response.PRODUCTS[i].CODE)
                    al.setAttribute("class", 'show-flower')
                    al.append(im);
                    al.append(divb);
                    al.append(divh);
                    divr.append(al);
                    $("#flower-list-con").append(divr);

                }
            }
        });
        $('html,body').scrollTop(0);

        // Replace the navigation items (not prev/next):            
        $(".pagination.flo-pag li").slice(1, -1).remove();
        getPageList(totalPages, currentPage, paginationSize).forEach(item => {
            $("<li>").addClass("page-item")
                .addClass(item ? "current-page" : "disabled")
                .toggleClass("active", item === currentPage).append(
                    $("<a>").addClass("page-link").attr({
                        href: "javascript:void(0)"
                    }).text(item || "...")
                ).insertBefore("#next-page");
        });
        // Disable prev/next when at first/last page:
        // $("#previous-page").toggleClass("disabled", currentPage === 1);
        // $("#next-page").toggleClass("disabled", currentPage === totalPages);
        if (currentPage === 1) {
            $("#previous-page").hide()
        } else {
            $("#previous-page").show()
        }
        if (currentPage === totalPages) {
            $("#next-page").hide()
        } else {
            $("#next-page").show()
        }

        return true;
    }

    // Include the prev/next buttons:
    $(".pagination.flo-pag ").append(
        $("<li>").addClass("page-item").attr({
            id: "previous-page"
        }).append(
            $("<a>").addClass("page-link").attr({
                href: "javascript:void(0)"
            }).text("Prev")
        ),
        $("<li>").addClass("page-item").attr({
            id: "next-page"
        }).append(
            $("<a>").addClass("page-link").attr({
                href: "javascript:void(0)"
            }).text("Next")
        )
    );
    // Show the page links
    $("#jar").show();
    showPage(1);

    // Use event delegation, as these items are recreated later    
    $(document).on("click", ".pagination.flo-pag  li.current-page:not(.active)", function() {
        return showPage(+$(this).text());
    });
    $("#next-page").on("click", function() {
        return showPage(currentPage + 1);
    });

    $("#previous-page").on("click", function() {
        return showPage(currentPage - 1);
    });
}
window.addEventListener("turbolinks:load",()=>{
 
  for(var b=0;b<$('.rui').length;b++){
    var resourceUri = $('.rui').eq(b).text()
    var res = encodeURIComponent(resourceUri );
    res= "https://dist.bambuser.net/player/?resourceUri="+res+"&showViewerCount=true"
    $('.istr').eq(b).attr('src',res)
   
  }

})

window.addEventListener('message', function(event) {
  if (event.origin !== 'https://dist.bambuser.net') {
    // Don't trust messages from other origins
    return;
  }
  var videoEvent = event.data.videoEvent;
  if (videoEvent) {
    // console.log('video event:', videoEvent.name);
		// console.log('from:', event.source);
    if(($("#login").is(":visible") || $("#navMobItems").children("#login").length>0) && videoEvent.name =='play'){
      // alert("please log in")
      window.location.href =  window.location.href + "?do=login"
    }
  }
}, false);

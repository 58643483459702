window.addEventListener("turbolinks:load", ()=> {
  var show_error, stripeResponseHandler;
  $("#donateForm").submit(function (e) {
    e.preventDefault();
    
    var $form;
    
    $form = $(this);
    $form.find("input[type=submit]").prop("disabled", true);
    Stripe.card.createToken($form, stripeResponseHandler);
    return false;
  });
  stripeResponseHandler = function (status, response) {
    var $form, token;
    $form = $("#donateForm");
    $("div.spanner").addClass("show");
    $("div.overlay").addClass("show");
    if (response.error) {
      
      show_error(response.error.message);
      $form.find("input[type=submit]").prop("disabled", false);
      $("div.spanner").removeClass("show");
        $("div.overlay").removeClass("show");
    } else {
      token = response.id;
      
      if(typeof(token) != "undefined" && token.length) {
       
        $form.append($("<input type=\"hidden\" name=\"ccToken\" />").val(token));
        $("[data-stripe=number]").remove();
        $("[data-stripe=cvv]").remove();
        $("[data-stripe=exp-year]").remove();
        $("[data-stripe=exp-month]").remove();
        $form.get(0).submit();
        
      }
      else {
        show_error("Stripe Initialization has failed! Please reload the page and try again.")
      }
    }
    return false;
  };

  show_error = function (message) {
    console.log(message)
    alert(message);
    return false;
  };
});

window.addEventListener("turbolinks:load", () => {
    if (document.getElementById('map')) {
        initMap()
    }
    if (document.getElementById('payeeDet')) {
        payMap()
    }

    // load services maps
    $maps = $('.ob-widget-map');
    $maps.each(function(index, Element) {
        // The location service
        var el = $(Element).children(".serviceLocation")
        var service = { lat: 37.0902, lng: -95.7129 };
        if (el.text().includes(",")) {
            $(Element).addClass('map')
            $(Element).css('height', '250px')
            var coords = el.text().split(",");
            var lat = parseFloat(coords[0])
            var lng = parseFloat(coords[1].replace(' ', ''))
            service = { lat: lat, lng: lng };
            var myOptions = {
                'zoom': 10,
                'mapTypeId': google.maps.MapTypeId.ROADMAP,
                center: service
            };
            // The map, centered at service
            var map = new google.maps.Map(
                Element, myOptions);
            // The marker, positioned at service
            var marker = new google.maps.Marker({
                position: service,
                map: map,
                draggable: false,
                title: "Location"
            });
        } else {
            $(Element).css('height', '0px')
        }
        $(Element).css("display", "block")
        $(Element).css("margin-top", "0px")
    });

    $ser_maps = $('.service_map');

    $ser_maps.each(function(index, Element) {
        var el = $('.service_coord').eq(index)[0]
        var service = { lat: 37.0902, lng: -95.7129 };
        var input = $(".servAdd").eq(index)[0]
        var card = $(".my_pac_card").eq(index)[0]
        var time = document.getElementsByClassName('my_time');
        var myt = $(time).eq(index)[0]
        if ($(myt).val() == '') {
            (myt).defaultValue = "12:00";
        }

        $mType = ($('.memType'))


        $mType.each(function(index, Ele) {

            noHome()
            $(Ele).on('change', function() {

                noHome()
            });
            $('#noService').on('click', function() {
                if ($(this).is(':checked') == false) {

                    noHome()
                    $(Ele).on('change', function() {

                        noHome()
                    });
                }
            })
        })

        function noHome() {
            if ($(el).attr('value') == "" || $(el).attr('value') == undefined) {
                service = { lat: 37.0902, lng: -95.7129 };
                $(Element).css('height', '0px')
                updateInt(Element, service, input, card, el, index)
            } else {
                $(Element).addClass('map')
                $(Element).css('height', '250px')
                var coords = $(el).attr('value')
                coords = coords.split(",");
                var lat = parseFloat(coords[0])
                var lng = parseFloat(coords[1].replace(' ', ''))
                service = { lat: lat, lng: lng };
                updateInt(Element, service, input, card, el, index)
            }
        }

        if ($('.obit_addre').eq(index).val() != null) {
            $('.obit_addre').eq(index).siblings('.obad').css("display", "flex")
        }
    });


    function updateInt(Element, service, input, card, el, index) {
        var myOptions = {
            'zoom': 10,
            'mapTypeId': google.maps.MapTypeId.ROADMAP,
            center: service
        };
        // The map, centered at service
        var map = new google.maps.Map(
            Element, myOptions);
        // The marker, positioned at service

        var marker = new google.maps.Marker({
            position: service,
            map: map,
            draggable: true,
            title: "Location"
        });

        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

        var autocomplete = new google.maps.places.Autocomplete(input);

        autocomplete.bindTo('bounds', map);

        autocomplete.setFields(
            ['address_components', 'geometry', 'icon', 'name']);

        var infowindow = new google.maps.InfoWindow();

        autocomplete.addListener('place_changed', function() {
            infowindow.close();

            marker.setVisible(false);
            var place = autocomplete.getPlace();

            if (!place.geometry) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");
                return;
            }

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(17); // Why 17? Because it looks good.
            }

            marker.setPosition(place.geometry.location);
            marker.setVisible(true);
            map.setZoom(17);
            map.setCenter(place.geometry.location)
            var pla = place.geometry.location
            pla = String(pla)
            pla = pla.replace('(', '')
            pla = pla.replace(')', '')
            $(el).val(pla)

            google.maps.event.addListener(marker, 'dragend', function(event) {
                var lat = event.latLng.lat();
                var lng = event.latLng.lng();
                var location = (lat + "," + lng)
                $(el).val(location)
                marker.title = (location)
                    // geocodePosition(marker.getPosition());
            });

            var add_comp = place.address_components
            var st = ''
            var zi = ''
            var x = index
            updateLocation(add_comp, st, zi, x, Element)

        });

        $(Element).css("display", "block")
        $(Element).css("margin-top", "0px")
    }

    function updateLocation(add_comp, st, zi, x, Element) {
        // console.log(add_comp)
        for (var s = 0; s < add_comp.length; s++) {
            if (add_comp[s].types[0] == 'street_number') {
                st = add_comp[s].long_name
            }
            if (add_comp[s].types[0] == 'route') {
                st += ' ' + add_comp[s].long_name
            }
            $('.obit_addre').eq(x).val(st)
            if (add_comp[s].types[0] == 'neighborhood') {
                $('.obit_addre2').eq(x).val(add_comp[s].long_name)
            }

            if (add_comp[s].types[0] == 'locality') {
                $('.obit_city').eq(x).val(add_comp[s].long_name)
            } 
            else if(add_comp[s].types[0]=='administrative_area_level_3'){
              $('.obit_city').eq(x).val(add_comp[s].long_name)
            }
            
            if (add_comp[s].types[0] == 'sublocality_level_1') {
                $('.obit_addre2').eq(x).val(add_comp[s].long_name)
            }

            if (add_comp[s].types[0] == 'administrative_area_level_1') {
                $('.obit_state').eq(x).val(add_comp[s].long_name)
            }

            if (add_comp[s].types[0] == 'postal_code') {
                zi += (add_comp[s].long_name)
            }
            if (add_comp[s].types[0] == 'postal_code_suffix') {
                zi += '-' + (add_comp[s].long_name)
            }
            $('.obit_zip').eq(x).val(zi)
            if (add_comp[s].types[0] == 'country') {
                $(".obit_countr option[value=" + add_comp[s].short_name + "]").eq(x).prop('selected', true);
            }
            $(Element).css("display", "block")
            $(Element).css("margin-top", "0px")
            $(Element).css('height', '250px')
            $(Element).siblings('.obad').css("display", "flex")
        }
    }

    var options = {
        onKeyPress: function(cep, e, field, options) {
            var masks = ['00000-000', '0-00-00-00'];
            var mask = (cep.length > 7) ? masks[1] : masks[0];
        }
    }

    $('#obit_services')
        .on('cocoon:before-insert', function() {
            $('.phone_num').mask('(000) 000-0000', options);
            $('.obdate').mask('00/00/0000', options);
        })
        .on('cocoon:after-insert', function() {
            $('.phone_num').mask('(000) 000-0000', options);
            $('.obdate').mask('00/00/0000', options);
            var serclick = $('.ser_ti').length;
            serMap(serclick)
        })

    // $('.addServiceObit').click(function(){
    //   var serclick = $('.obit_location').length;
    // //   // setTimeout(function() { serMap(serclick) }, 20);
    //   // serMap(serclick)
    //   console.log(serclick)
    // })


    function serMap(ind) {
        ind -= 1
            // $(".obit_countr option[value='US']").prop('selected', true);
        $('.sepSer').css('display', 'block')
        $('.obdate').datepicker({
            dateFormat: "mm/dd/yy",
            changeMonth: true,
            changeYear: true,
            yearRange: "-120:+0"
        });


        $('.obdate').mask('00/00/0000', options);
        var maps = $('.service_map');
        var service = { lat: 37.0902, lng: -95.7129 };
        var input = $(".servAdd").eq(ind)[0]
            // console.log(input)
        var Element = $(input).siblings('.service_map')[0]
        var el = $(input).siblings('.service_coord')[0]
            // console.log(Element)
        var card = $(".my_pac_card").eq(ind)[0]
        var time = $('.my_time');
        var myt = $(time).eq(ind)[0]
        if ($(myt).val() == '') {
            // (myt).defaultValue = "12:00";
        }

        var options = {
            onKeyPress: function(cep, e, field, options) {
                var masks = ['00000-000', '0-00-00-00'];
                var mask = (cep.length > 7) ? masks[1] : masks[0];
            }
        }

        $('.phone_num').eq(ind).mask('(000) 000-0000', options);

        $('.obdate').mask('00/00/0000', options);

        $mType = ($('.memType'))

        $mType.each(function(ind, Ele) {

            noHome()
            $(Ele).on('change', function() {

                noHome()
            });
        })

        function noHome() {
            if ($(el).attr('value') == "" || $(el).attr('value') == undefined) {
                service = { lat: 37.0902, lng: -95.7129 };
                $(Element).css('height', '0px')
                updateInt(Element, service, input, card, el, ind)
            } else {
                $(Element).addClass('map')
                $(Element).css('height', '250px')
                var coords = $(el).attr('value')
                coords = coords.split(",");
                var lat = parseFloat(coords[0])
                var lng = parseFloat(coords[1].replace(' ', ''))
                service = { lat: lat, lng: lng };
                updateInt(Element, service, input, card, el, ind)
            }

        }



        if ($('.obit_addre').eq(ind).val() != null) {
            $('.obad').eq(ind).css("display", "flex")
        }
    }



    if ($('#payeeDet').is("")) {
        if ($('#payeeDet').get(0).checked) {
            $('.payeeAdd').val($('.funAdd').val())
            $('.payeezip').val($('.funzip').val())
            $('.payeeCi').val($('.funcity').val())
            $('.payeestate').val($('.funstate').val())
            $('.payeecountr').val($('.funcountr').val())
            $('.payeephone').val($('.funphone').val())
        }
    }

})


function initMap() {
    var map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: 37.0902, lng: -95.7129 },
        zoom: 8
    });
    var card = document.getElementById('pac-card-main');
    var input = $(".funAdd")[0]


    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

    var autocomplete = new google.maps.places.Autocomplete(input);

    // Bind the map's bounds (viewport) property to the autocomplete object,
    // so that the autocomplete requests use the current map bounds for the
    // bounds option in the request.
    autocomplete.bindTo('bounds', map);

    // Set the data fields to return when the user selects a place.
    autocomplete.setFields(
        ['address_components', 'geometry', 'icon', 'name']);

    var infowindow = new google.maps.InfoWindow();

    var marker = new google.maps.Marker({
        map: map,
        draggable: true,
        anchorPoint: new google.maps.Point(0, -29)
    });
    if ($('.coord').attr('value')) {
        var coords = $('.coord').attr('value')
        coords = coords.split(",");
        var lat = parseFloat(coords[0])
        var lng = parseFloat(coords[1].replace(' ', ''))
        service = { lat: lat, lng: lng };
        var marker = new google.maps.Marker({
            position: service,
            map: map,
            draggable: true,
            title: "Location"
        });
        map.setCenter(marker.getPosition());
        $('#map').css("display", "block")
    } else {
        var marker = new google.maps.Marker({
            map: map,
            draggable: true,
            anchorPoint: new google.maps.Point(0, -29)
        });
        $('#map').css("display", "none")
    }

    autocomplete.addListener('place_changed', function() {
        infowindow.close();
        marker.setVisible(false);
        var place = autocomplete.getPlace();
        if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17); // Why 17? Because it looks good.
        }

        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
        map.setZoom(17);
        var pla = place.geometry.location
        pla = String(pla)
        pla = pla.replace('(', '')
        pla = pla.replace(')', '')
        $('.coord').val(pla)
        google.maps.event.addListener(marker, 'dragend', function(event) {
            var lat = event.latLng.lat();
            var lng = event.latLng.lng();
            var location = (lat + "," + lng)
            $(".coord").val(location)
            marker.title = (location)
                // geocodePosition(marker.getPosition());
        });




        var add_comp = place.address_components
        var st = ''
        var zi = ''
        var nei = ', '
        var city = ''
        for (var s = 0; s < add_comp.length; s++) {
            if (add_comp[s].types[0] == 'street_number') {
                st = add_comp[s].long_name
            }
            if (add_comp[s].types[0] == 'route') {
                st += ' ' + add_comp[s].long_name
            }

            if (add_comp[s].types[0] == 'neighborhood') {
                nei += add_comp[s].long_name;
            } else if (add_comp[s].types[0] == ' sublocality_level_1') {
                nei += add_comp[s].long_name;
            }

            if (add_comp[s].types[0] == 'locality') {
                $('.funcity').val(add_comp[s].long_name);

            }
            else if(add_comp[s].types[0]=='administrative_area_level_3'){
                $('.funcity').val(add_comp[s].long_name)
              }
            

            $('.funAdd').val(st + nei)

            if (add_comp[s].types[0] == 'postal_code') {
                zi += (add_comp[s].long_name)
            }
            if (add_comp[s].types[0] == 'postal_code_suffix') {
                zi += '-' + (add_comp[s].long_name)
            }
            $('.funzip').val(zi)

            if (add_comp[s].types[0] == 'administrative_area_level_1') {
                $('.funstate').val(add_comp[s].long_name)
            }

            if (add_comp[s].types[0] == 'country') {
                $(".funcountr option[value=" + add_comp[s].short_name + "]").prop('selected', true);
            }
            $('#map').css("display", "block")

        }

    });
}


function payMap() {

    var input = $(".payeeAdd")[0]

    var autocomplete = new google.maps.places.Autocomplete(input);

    // Bind the map's bounds (viewport) property to the autocomplete object,
    // so that the autocomplete requests use the current map bounds for the
    // bounds option in the request.
    // autocomplete.bindTo('bounds', map);

    // Set the data fields to return when the user selects a place.
    autocomplete.setFields(
        ['address_components', 'geometry', 'icon', 'name']);

    var infowindow = new google.maps.InfoWindow();


    autocomplete.addListener('place_changed', function() {
        infowindow.close();
        var place = autocomplete.getPlace();
        if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert("No details available for input: '" + place.name + "'");
            return;
        }


        var add_comp = place.address_components
        var st = ''
        var zi = ''
        var nei = ', '
        var city = ''
        for (var s = 0; s < add_comp.length; s++) {
            if (add_comp[s].types[0] == 'street_number') {
                st = add_comp[s].long_name
            }
            if (add_comp[s].types[0] == 'route') {
                st += ' ' + add_comp[s].long_name
            }

            if (add_comp[s].types[0] == 'neighborhood') {
                nei += add_comp[s].long_name;
            } else if (add_comp[s].types[0] == ' sublocality_level_1') {
                nei += add_comp[s].long_name;
            }

            if (add_comp[s].types[0] == 'locality') {
                $('.payeeCi').val(add_comp[s].long_name);
            }

            $('.payeeAdd').val(st + nei)

            if (add_comp[s].types[0] == 'postal_code') {
                zi += (add_comp[s].long_name)
            }
            if (add_comp[s].types[0] == 'postal_code_suffix') {
                zi += '-' + (add_comp[s].long_name)
            }
            $('.payeezip').val(zi)

            if (add_comp[s].types[0] == 'administrative_area_level_1') {
                $('.payeestate').val(add_comp[s].long_name)
            }

            if (add_comp[s].types[0] == 'country') {
                $(".payeecountr option[value=" + add_comp[s].short_name + "]").prop('selected', true);
            }

        }

    });
}
window.addEventListener("load", () => {

    if ($(".postOps").is(":visible")) {
        if ($("#fsome").get(0).checked == true) {
            $(".nt-box").css("display", "none")
            $(".postfor_btn ").css("display", "block")
        }
        if ($("#fmy").get(0).checked == true) {
            $(".nt-box").css("display", "block")
            $(".postfor_btn ").css("display", "block")
        }

    }

    var tot = 0
    if (($("#doAmount").val()) == "") {
        tot += 0
    }

    if (($("#tip_amount").val()) == "" || ($("#tip_amount").val()) == undefined) {
        tot += 0
    }

    if (parseInt($("#doAmount").val()) >= 0) {
        tot += parseInt($("#doAmount").val())
    }
    if (parseInt($("#tip_amount").val()) >= 0) {
        tot += parseInt($("#tip_amount").val())
    }
    $("#donation-total").text(tot)

})
window.addEventListener("turbolinks:load", () => {

    //setTimeout("console.clear()", 3000)

    $('.trix-button--icon-increase-nesting-level').removeAttr('disabled')
    $('.trix-button--icon-decrease-nesting-level').removeAttr('disabled')

    var loga = sessionStorage.log
    if (loga == 'facebook') {
        $("a.profile").attr("data-turbolinks", "true")
    } else {
        $("a.profile").attr("data-turbolinks", "false")
    }

    navigationFx()
    tabsFx()

    let serviceDrop = document.querySelectorAll(".dropdown-header")
    serviceDrop.forEach(element => {
        dropdownFx(element)
    });
    popup()
    let loginModal = document.querySelector(".login-modal-open")
    let signUpModal = document.querySelector(".signup-modal-open")
    signUpModalFx(loginModal, ".login-modal", 0, ".x-1")
    signUpModalFx(signUpModal, '.signup-modal', 1, ".x-2")

    $('#obituary_date_of_birth').blur(function() {
        $('#obituary_date_of_birth').attr('placeholder', 'Date of Birth*');
    });
    $('#obituary_date_of_death').blur(function() {
        $('#obituary_date_of_death').attr('placeholder', 'Date of Death*');
    });
    $(".search_icon").on('click', function() {

        $("#searchArea").val("");
        $(".clearSearch").addClass("addSearch")
        $("#navItems").addClass("noNav")
        $("#navItems").removeClass("flexNav")
        $(".nav_link-block #searchArea").focus();
        $('.mobMen').css('display', 'none')
        $('.mobUIc').css('display', 'none')
        if ($("#navItems a").css('display') == 'none') {
            // some code..
            $("#searchArea").css("width", " 45vw")
            if ($(".parlogo").css('display') == 'inline-block') {
                $(".search_icon").css("top", "0px")
                $(".search_icon").css("position", "relative")
                $(".fa-bars").css("top", "-2px")

            }
        } else if ($(".parlogo").css('display') == 'inline-block') {
            $("#searchArea").css("width", " 70%")
                // $(".serach_icon").css("top","0px")
                // $(".serach_icon").css("position","relative")

        } else {
            $("#searchArea").css("width", " 76vw")
        }

    })

    $(".clearSearch").on('click', function() {
        $("#searchArea").css("width", " 0px")
        setTimeout(() => {
            $(".clearSearch").removeClass("addSearch")
            $("#navItems").addClass("flexNav")
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                // some code..
                $('.mobMen').css('display', 'block')
                $('.mobUIc').css('display', 'flex')
            }
        }, 200);
    })

    $allimgs = $('img')
    $allimgs.each(function(index, Elem) {
        var sr = $(Elem).attr("src")
        if (sr != '') {
            var set = sr + " 1x"
            $(Elem).attr("srcset", set)
        }
    })

    if (window.location.href.indexOf("ob-cre") > -1) {
        $('#inv-manger').get(0).click();
    }

    $(".obit_countr option[value='US']").prop('selected', true);

    $(".payeecountr option[value='US']").prop('selected', true);

    $("#organization_country option[value='US']").prop('selected', true);

    $("#doAmount").on("keyup keydown change", function() {

        var tot = 0
        if (($("#doAmount").val()) == "") {
            tot += 0
        }

        if (($("#tip_amount").val()) == "" || ($("#tip_amount").val()) == undefined) {
            tot += 0
        }
        if (parseInt($("#doAmount").val()) >= 0) {
            tot += parseInt($("#doAmount").val())
        }
        if (parseInt($("#tip_amount").val()) >= 0) {
            tot += parseInt($("#tip_amount").val())
        }

        $("#donation-total").text(tot)
    })
    $("#customTip").on("keyup keydown change", function(evt) {

        var e = event || evt; // for trans-browser compatibility
        var charCode = e.which || e.keyCode;

        if (charCode != 9) {
            var $rad = $(".doOpts input:radio")
            $rad.each(function(index, Element) {
                Element.checked = false
            })

            var tot = 0
            if (($("#doAmount").val()) == "") {
                tot += 0
            }
            $("#tip_amount").val(parseInt($("#customTip").val()))
            if (parseInt($("#tip_amount").val()) >= 0) {
                tot += 0
            }

            if (parseInt($("#doAmount").val()) >= 0) {
                tot += parseInt($("#doAmount").val())
            }
            if (parseInt($("#tip_amount").val()) >= 0) {
                tot += parseInt($("#tip_amount").val())
            }
            $("#donation-total").text(tot)

        }

    })

    $(".signModal").on('click', function() {
        var close = $(this).parent().siblings('.modalp-close')
        $(close).click();
    })

    $('#odoAmount').mask("#,##0.00", {
        reverse: true
    });

    $(".logModal").on('click', function() {
        var close = $(this).parent().siblings('.modalp-close')
        $(close).click();
    })

    if ($('#login').is(':visible') || $('.mobMen').is(':visible')) {
        if (window.location.href.indexOf("do=login") > -1) {
            $('#login').get(0).click();
            var uri = window.location.toString();
            if (uri.indexOf("?do=login") > 0) {
                var clean_uri = uri.substring(0, uri.indexOf("?do=login"));
                window.history.replaceState({}, document.title, clean_uri);

            }
        }
    }

    $(document).on('click', '.causes-filter', function() {
        $("#charities").css("display", "none")
    })

    if ($('#signup').is(':visible') || $('.mobMen').is(':visible') || $('.powby').is(':visible')) {
        if (window.location.href.indexOf("do=signup") > -1) {
            $('#signup').get(0).click()
            var uri = window.location.toString();
            if (uri.indexOf("?do=signup") > 0) {
                var clean_uri = uri.substring(0, uri.indexOf("?do=signup"));
                window.history.replaceState({}, document.title, clean_uri);

            }
        }
    }

    if ($('.do_btn').is(':visible')) {
        if (window.location.href.indexOf("do=success") > -1) {
            $('#succDo').click();
            var uri = window.location.toString();
            if (uri.indexOf("?do=success") > 0) {
                var clean_uri = uri.substring(0, uri.indexOf("?do=success"));
                window.history.replaceState({}, document.title, clean_uri);

            }
        }
    }

    if ($('#login').is(':visible') || $('.mobMen').is(':visible')) {
        if (window.location.href.indexOf("do=email-sent") > -1) {
            $('#fgpass').get(0).click();
            var uri = window.location.toString();
            if (uri.indexOf("?do=email-sent") > 0) {
                var clean_uri = uri.substring(0, uri.indexOf("?do=email-sent"));
                window.history.replaceState({}, document.title, clean_uri);

            }
        }
    }

    $('.mobMen').on('click', function() {
        $('#navItems').css('position', 'absolute')
        if ($('#navMobItems').css('display') == 'none') {
            $('#navMobItems').css('display', 'block')
        } else {
            $('#navMobItems').css('display', 'none')
        }
    })

    var causeopt = ''

    if ($('.se_time').is(':visible')) {
        var dt = ""
        var hours = ""
        var minutes = ""
        var AmOrPm = ""
        for (var x = 0; x < $('.se_time').length; x++) {
            dt = $('.se_time').eq(x).text().split(":")
            hours = dt[0]
            minutes = dt[1]
            AmOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = (hours % 12) || 12;
            $('.se_time').eq(x).text(hours + ":" + minutes + " " + AmOrPm)
        }
    }

    var options = {
        onKeyPress: function(cep, e, field, options) {
            var masks = ['00000-000', '0-00-00-00'];
            var mask = (cep.length > 7) ? masks[1] : masks[0];

        }
    };
    $('.phone_num').mask('(000) 000-0000', options);

    $('.obdate').mask('00/00/0000', options);

    $('.obdate').datepicker({

        dateFormat: "mm/dd/yy",
        changeMonth: true,
        changeYear: true,
        yearRange: "-120:+0"
    });

    $(".deathdate").datepicker('option', {
        maxDate: new Date(),
        dateFormat: "mm/dd/yy",
        minDate: new Date($("birthdate").val()),
        startDate: new Date($("birthdate").val()),
        changeMonth: true,
        changeYear: true,
        yearRange: "-120:+0"
    })
    $(".birthdate").datepicker('option', {
        maxDate: new Date(),
        dateFormat: "mm/dd/yy",
        changeMonth: true,
        changeYear: true,
        yearRange: "-120:+0"
    })

    $("#obituary_date_of_death").attr("min", $("#obituary_date_of_birth").val())

    $(".sharewats").on("keyup keydown change", function() {
        var nu = $(".sharewats").val()
        nu = nu.replace('(', 1)
        nu = nu.replace(')', '')
        nu = nu.replace('-', '')
        nu = nu.replace(' ', '')
        if (nu.length == 11) {
            $('#wat-hid').val(nu).trigger('change');
        }
    })
    if ($('#wat-hid').val() != '' && $('#wat-hid').is('.visible')) {
        mywat()
    }

    $('#wat-hid').on('change', function() {
        mywat()
    })

    $(document).on("change paste keyup click select keydown", "input.direc", function() {
        if ($('.direc').prop('validity').typeMismatch) {
            $('.direerror').css("display", 'block')
        } else {
            $('.direerror').css("display", 'none')
        }
    })

    function mywat() {
        var wat = $("#whatsapp-link").attr('href')
        wat = wat.split('wa.me/')
        var strArray = wat[1].split("?");
        var str = strArray[0].replace(/./gi, '');
        str = str + "?" + strArray[1];
        if (+$('#wat-hid').val().length == 11) {
            wat = wat[0] + 'wa.me/' + $('#wat-hid').val() + str
            $("#whatsapp-link").attr('href', wat)
        }
    }

    $("#obituary_date_of_birth").on("keyup keydown change", function() {
        $("#obituary_date_of_death").attr("min", $("#obituary_date_of_birth").val())
    })

    setTimeout(function() {
        $(".message").hide();
    }, 7000);

    if ($(".postOps").is(':visible')) {
        if ($("#fsome").get(0).checked == true) {
            $(".nt-box").css("display", "none")
            $(".postfor_btn ").css("display", "block")

        }
        if ($("#fmy").get(0).checked == true) {

            $(".nt-box").css("display", "block")
            $(".postfor_btn ").css("display", "block")

        }
    }

    if ($("#service_time").is(":visible")) {
        if ($('#service_time').val() == '' || $('#service_time').val() == undefined) {
            document.getElementById("service_time").defaultValue = "12:00";
        }
    }

    $('.clearS').on('click', function() {
        var se = $(this).siblings('#searchArea2')
        $(se).val("")
    })
    if ($("#navItems a").css('display') == 'none') {

        if ($('.obNavList li').length == 4) {
            $('.obNavList li').css("width", "23%")
        } else if ($('.obNavList li').length == 2) {
            $('.obNavList li').css("width", "48%")
        } else {
            $('.obNavList li').css("width", "31.33%")
        }
    }
    for (var i = 0; i < $(".memType").length; i++) {
        if ($(".memType").eq(i).val() != '') {
            $(".memType").eq(i).parent().siblings().children('.dropdown_header-text').text($(".memType").eq(i).val())
        } else {
            $(this).parent().siblings().children('.dropdown_header-text').text("New Event")
        }
        $(".memType").eq(i).siblings('.obit_location').attr("required", "required")
        $(".memType").eq(i).siblings().children('.obit_timezone').attr("required", "required")
        $(".memType").eq(i).attr("required", "required")
    }

    $(".memType").on("change paste keyup click select keydown", function() {
        if ($(this).val() != '') {
            $(this).parent().siblings().children('.dropdown_header-text').text($(this).val())
            $(this).siblings('.obit_location').attr("required", "required")
            $(this).attr("required", "required")
            $(this).siblings().children('.obit_timezone').attr("required", "required")
        } else {
            $(this).parent().siblings().children('.dropdown_header-text').text("New Event")
                // $(this).siblings('.obit_location').attr("required", false)
                // $(this).attr("required", false)
                // $(this).siblings().children('.obit_timezone').attr("required", false)
        }
    })

    $('.showf-det').on('click', function() {
        var ind = ($('.showf-det').index($(this)))
        var se = $('.funH-det').eq(ind)
        var h = $('.funH-hr').eq(ind - 1)
        var rep = $(".vrep").eq(ind)
        $(se).css('display', 'block')
        $(h).css('display', 'block')
        $(rep).css('display', 'inline')
        $('.hidef-det').eq(ind).css('display', 'block')
        $(this).css('display', 'none')
        $(this).parent().addClass('fhback')

    })

    $('.hidef-det').on('click', function() {
        var ind = ($('.hidef-det').index($(this)))
        var se = $('.funH-det').eq(ind)
        var h = $('.funH-hr').eq(ind - 1)
        var rep = $(".vrep").eq(ind)
        $(se).css('display', 'none')
        $(h).css('display', 'none')
        $(rep).css('display', 'none')
        $(this).css('display', 'none')
        $('.showf-det').eq(ind).css('display', 'block')
        $(this).parent().removeClass('fhback')
    })
    checkTrm()
    $(".agrCk").on('click', function() {
        checkTrm()
    })

    function checkTrm() {

        if ($('.agrCk').is(":visible")) {
            if ($('.agrCk:checked').length == $('.agrCk').length) {
                $('.agrBtn').attr('disabled', false)
                $('#payeeAgr').prop("checked", true);
            } else {
                $('.agrBtn').attr('disabled', "disabled")
                $('#payeeAgr').attr('checked', false)
                $('#payeeAgr').prop("checked", false);
                $('#subPayment').addClass('disableda')
            }
        }
    }

    $('.agreeT').on('scroll', function() {
        if ($(this).scrollTop() +
            $(this).innerHeight() >=
            ($(this)[0].scrollHeight - 80)) {

            $('#opt1').attr('disabled', false)
        } else {

            $('#opt1').attr('disabled', "disabled")
        }
    });

    $('.agrBtn').on('click', function() {
        $('.close').click()
        $('#payeeAgr').attr('checked', 'checked')
        $('#payeeAgr').prop("checked", true);
        $('#payeeAgr').attr('disabled', 'disabled')
        $('#subPayment').removeClass('disableda')
    })

    $('#subPayment').on('click', function() {
        if ($('#payeeAgr').is(":checked")) {
            $('#payeeAgr').attr('disabled', false)
        }
    })

    function getTeam() {
        var new_mem = []
        if ($(".new_tem").is(":visible")) {
            $(".new_tem").attr("required", "required")
            for (var i = 0; i < ($(".new_tem").length); i++) {
                if ($(".new_tem").eq(i).val() != "") {
                    new_mem.push(true)
                } else {
                    new_mem.push(false)
                }
            }
            if ((new_mem.every((val, i, arr) => val === arr[0])) && new_mem[0] == true) {
                $(".funeral-submit").val("Confirm")
                $(".funeral-submit").attr("data-disable-with", "Confirm")
                $("#organization_next_step").val(4)
                $(".new_tem").attr("required", "required")
            } else if ((new_mem.every((val, i, arr) => val === arr[0])) && new_mem[0] == false) {

                $(".funeral-submit").val("Next")
                $(".funeral-submit").attr("data-disable-with", "Next")
                $("#organization_next_step").val(6)
                $(".new_tem").attr("required", false)
            } else {
                $(".funeral-submit").val("Confirm")
                $(".funeral-submit").attr("data-disable-with", "Confirm")
                $("#organization_next_step").val(4)
                $(".new_tem").attr("required", "required")
            }
        } else if ($(".skip_team").is(":visible")) {
            $(".new_tem").attr("required", false)
            $(".funeral-submit").val("Next")
            $(".funeral-submit").attr("data-disable-with", "Next")
            $("#organization_next_step").val(6)
        }
    }
    getTeam()

    $('.new_tem').on("change paste keyup click select", function() {
        getTeam()
    })

    $(".skip_payee").on("click", function() {
        $("#payee :input").attr("required", false)
        $("#payee :select").attr("required", false)
    })

    if ($('#payeeAgr').is(":checked")) {
        $('#subPayment').removeClass('disableda')
        $('#payeeAgr').attr('disabled', 'disabled')
    } else {
        $('#subPayment').addClass('disableda')
        $('#payeeAgr').attr('disabled', 'disabled')
    }

    $('.steps_text.active').eq(-1).addClass('shwStepp')

    $('.creO-radio').on('click', function() {
        if ($(this).attr("id") == "fsome") {
            $(".nt-box").css("display", "none")
        }
        if ($(this).attr("id") == "fmy") {
            $(".nt-box").css("display", "block")
        }
        $(".postfor_btn ").css("display", "block")
    });

    $("#yHome").on('click', function() {
        if ($('.obitSavedHome').attr('id') == "" || $('.obitSavedHome').length == 0 && $("#obituary_invite_home_email").val() == '') {
            $('.obitHome').css("display", "block");

            if ($('#st2-fun-home').children('.col-9').length == 0 && $("#obituary_invite_home_email").val() == '') {
                $('.funSearch').addClass('des-search')
            }

        } else {
            $('.obitSavedHome').css("display", "flex");
            $('.obitHome').css("display", "none")
            $("#ben_uuid").val($('.obitSavedHome').attr("id"))
        }

    })
    $("#nHome").on('click', function() {
        $('.obitHome').css("display", "none")
        $('.funSearch').removeClass('des-search')
        $('#obituary_organization_id').val("")
        $('.obitSavedHome').css("display", "none");
    })

    $('form').submit(function(e) {
        $(this).find("input[class='sendCont']").prop('disabled', 'disabled');
        // var x = parseInt($('#odoAmount').val())
        // if(x<1){
        //     e.preventDefault()
        //     // alert("Donation amount must be at least $1")
        // }
        // else if (x>=1){
        //     $('form').submit()
        // }
    });

    // $("#contSub").click(function(e){
    //     var subForm = document.querySelector("#contSub");
    //     if($(".recaptcha-checkbox-checked").is(":visible")==false){
    //         e.preventDefault()
    //         subForm.setAttribute("disabled","disabled");
    //     }
    //     else{
    //         subForm.removeAttribute("disabled");
    //     }

    // })

    $("#decn").click(function() {
        $("#obituary_first_name").attr("placeholder", "First Name*")
        $("#obituary_middle_name").attr("placeholder", "Middle Name")
        $("#obituary_last_name").attr("placeholder", "Last Name*")
    })
    $("#decy").click(function() {
        $("#obituary_first_name").attr("placeholder", "Deceased's First Name*")
        $("#obituary_middle_name").attr("placeholder", "Deceased's Middle Name")
        $("#obituary_last_name").attr("placeholder", "Deceased's Last Name*")
    })
    if ($("#decy").is(":visible")) {

        if ($("#decy").checked == true) {
            $("#obituary_first_name").attr("placeholder", "Deceased's First Name*")
            $("#obituary_middle_name").attr("placeholder", "Deceased's Middle Name")
            $("#obituary_last_name").attr("placeholder", "Deceased's Last Name*")
        } else {
            $("#obituary_first_name").attr("placeholder", "First Name*")
            $("#obituary_middle_name").attr("placeholder", "Middle Name")
            $("#obituary_last_name").attr("placeholder", "Last Name*")
        }
    }

    if ($(".obitSavedHome").is(":visible")) {
        $("#yHome").prop("checked", true);
        $("#ben_uuid").val($('.obitSavedHome').attr("id"))
    }

    if ($("#yHome").is(":visible")) {

        if ($("#yHome").get(0).checked == true) {
            if ($('.obitSavedHome').attr('id') == "" || $('.obitSavedHome').length == 0 && $("#obituary_invite_home_email").val() == '') {
                $('.obitHome').css("display", "block")
                if ($('#st2-fun-home').children('.col-9').length == 0 && $("#obituary_invite_home_email").val() == '') {
                    $('.funSearch').addClass('des-search')
                } else {
                    $(".invi_email").text($("#obituary_invite_home_email").val())
                    $("#invite_for").css("display", "flex")
                }
            } else {
                $('.obitSavedHome').css("display", "flex");
                $('.obitHome').css("display", "none")
                $("#ben_uuid").val($('.obitSavedHome').attr("id"))
            }

        }
        if ($("#nHome").get(0).checked == true) {
            $('.obitHome').css("display", "none")
            $('.funSearch').removeClass('des-search')
            $('#obituary_organization_id').val("")
            $('.obitSavedHome').css("display", "none");

        }
    }

    if ($("#privateO").is(":visible")) {

        if ($("#privateO").get(0).checked == true) {
            $('.nt-box_pri').css("display", "block")
        }
        if ($("#publicO").get(0).checked == true) {
            $('.nt-box_pri').css("display", "none")
        }
    }

    $("#privateO").on('click', function() {
        $('.nt-box_pri').css("display", "block")
    })
    $("#publicO").on('click', function() {
        $('.nt-box_pri').css("display", "none")
    })

    var socialFloat = document.querySelector('.rside');
    var footer = document.querySelector('.footer');
    var cond_sec = document.querySelector('.condolences-section');

    function checkOffset() {
        function getRectTop(el) {
            var rect = el.getBoundingClientRect();
            return rect.top;
        }

        if ((getRectTop(socialFloat) + document.body.scrollTop) + socialFloat.offsetHeight >= (getRectTop(footer) + document.body.scrollTop) - 10)
            $(socialFloat).removeClass('rside-scroll').addClass('rside-stop')
        $('.rside-stop').css('bottom', "auto")
        if (document.body.scrollTop + window.innerHeight < (getRectTop(footer) + document.body.scrollTop))
            $(socialFloat).removeClass('rside-stop').addClass('rside-scroll')
        $('.rside-stop').css('bottom', footer.offsetHeight + 10 + "px")

    }
    if ($(socialFloat).is(':visible')) {
        document.addEventListener("scroll", function() {
            checkOffset();
        });
    }

    $('.save_draft').on('click', function(e) {
        $('.edit_obituary *').attr('required', false);
        $('.new_obituary *').attr('required', false);
        $('.edit_organization *').attr('required', false);
        $('.new_organization *').attr('required', false);
    })

    $(document).on('ready load', function() {
        $(".conPass").on('change paste keyup click select keydown', checkPasswordMatch);
    });

    $(".signUpBtn").on('click', function() {
        $(".step1_signup").css("display", "none")
        $(".step2_signup").css("display", "block")
    })
    if ($("#user_email").val() != undefined) {
        if ($("#user_email").val() != "" && $("#user_password").val() != "" && $("#user_password").val().length >= 8 && $("#user_password_confirmation").val() != "" && $("#user_password_confirmation").val().length >= 8 && $(".passError").css("display") == "none") {
            $(".signUpBtn").attr("disabled", false)
        } else {
            $(".signUpBtn").attr("disabled", "disabled")
        }
    }

    $('.sfi').on("change paste keyup click select keydown", function() {
        if ($("#user_email").val() != "" && $("#user_password").val() != "" && $("#user_password").val().length >= 8 && $("#user_password_confirmation").val() != "" && $("#user_password_confirmation").val().length >= 8 && $(".passError").css("display") == "none") {
            $(".signUpBtn").attr("disabled", false)
        } else {
            $(".signUpBtn").attr("disabled", "disabled")
        }
    })

    $('.check_terms').on('click', function(e) {
        if ($('#agreement').is(':checked')) {
            $('.edit_obituary').submit()
        } else {
            alert("Please agree to the terms first")
        }
    })

    $('.export-csv').on('click', function(e) {
        var al = $(this)
        e.preventDefault()
        var x = $(al).attr("href")
        var reg = window.location.href.split("/")
        var w = reg[0] + "//" + reg[2]
            // console.log(w + x)
        $(".export-alert").css("display", "block")
        $(".export-alert .message").css("display", "block")
        setTimeout(function() {
            $(".export-alert").css("display", "none")
            $(".export-alert .message").css("display", "none")
            window.location = w + x
        }, 10000);
    })

    $("form input, form select, form textarea").on("invalid", function() {
        $(this).addClass("invalidField")
    });

    $(".subserv").click(function() {
        // console.log($('.obit_location').prop('validity').valueMissing)
        var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        var is_opera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        var is_Edge = navigator.userAgent.indexOf("Edge") > -1;
        var is_chrome = !!window.chrome && !is_opera && !is_Edge;
        var is_explorer = typeof document !== 'undefined' && !!document.documentMode && !is_Edge;

        for (var i = 0; i < $("#obit_services .nested-fields").length; i++) {

            if ($('.obit_timezone').eq(i).prop('validity').valueMissing == true) {
                if (is_safari || is_chrome || is_Edge || is_explorer || is_opera) {

                    $('.obit_timezone').eq(i).addClass("invalidField")
                }
                $('.obit_timezone').eq(i).parent().parent().parent().parent().parent().removeClass("content-hidden")
                    // console.log("invalid")
            }
            if ($('.obit_location').eq(i).prop('validity').valueMissing == true) {
                if (is_safari || is_chrome || is_Edge || is_explorer || is_opera) {

                    $('.obit_location').eq(i).addClass("invalidField")
                }
                $('.obit_location').eq(i).parent().removeClass("content-hidden")
                    // console.log("invalid")
            }
            if ($('.memType').eq(i).prop('validity').valueMissing == true) {
                if (is_safari || is_chrome || is_Edge || is_explorer || is_opera) {

                    $('.memType').eq(i).addClass("invalidField")
                }
                $('.memType').eq(i).parent().removeClass("content-hidden")
                    // console.log("invalid")
            }
        }

    })

    $(document).on("click", "span.shwDelopt", function() {
        $(this).siblings('.delopt').css('display', 'block')
        $(this).css('display', 'none')
    })
    $('.hiDelopt').on('click', function() {
        $(this).parent('.delopt').css('display', 'none')
        $(this).parent().siblings('.shwDelopt').css('display', 'block')
    })

    if ($("#condolences").is(":visible")) {
        getvideo()

        const targetNode = document.getElementById('condolences');
        const config = {
            attributes: false,
            childList: true,
            subtree: false
        };
        const callback = function(mutationsList, observer) {
            // Use traditional 'for loops' for IE 11
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {

                    setTimeout(function() {
                        getvideo()
                    }, 2000);
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);

        function getvideo() {
            var videoUrl = $('.cond_link')
            for (var v = 0; v < videoUrl.length; v++) {
                var condo = $(videoUrl).eq(v).text()
                condo = condo.split(" ")
                var videoId = ""
                for (var x = 0; x < condo.length; x++) {
                    if (condo[x].includes("youtube") || condo[x].includes("vimeo")) {
                        videoId = condo[x]
                        condo.splice(x, 1)
                        condo = condo.toString();
                        condo = condo.replace(/,/g, " ");
                        $(videoUrl).eq(v).html(condo)

                    }

                }
                if (videoId.includes("youtube")) {
                    videoId = parseVideoUrl(videoId)
                    videoId = videoId.replace(/\s/g, '')
                    $('.media-preview').eq(v).html("<iframe width='100%' height='415' src='https://www.youtube.com/embed/" + videoId + "'" + "frameborder='0'  allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen>  </iframe>")
                    $('.media-preview').eq(v).css('height', '415px')

                } else if (videoId.includes("vimeo")) {
                    videoId = parseVimeoUrl(videoId)
                    videoId = videoId.replace(/\s/g, '')
                    $('.media-preview').eq(v).html("<iframe width='100%' height='415' src='https://player.vimeo.com/video/" + videoId + "'" + "frameborder='0'  allow=' autoplay; fullscreen' allowfullscreen>  </iframe>")
                    $('.media-preview').eq(v).css('height', '415px')

                } else if (!videoId.includes("vimeo") && !videoId.includes("youtube")) {
                    var lik = $(videoUrl).eq(v).children('a')[0]
                    if (lik != undefined) {
                        $(lik).attr('target', '_blank')
                    }
                }
            }
        }

        function parseVideoUrl(videoUrl) {
            var id = videoUrl.replace("https://www.youtube.com/watch?v=", "")
            if (id.includes("&")) {
                id = id.split("&")
                id = id[0]
            }
            return id
        }

        function parseVimeoUrl(videoUrl) {
            var id = videoUrl.replace("https://vimeo.com/", "")
            if (id.includes("&")) {
                id = id.split("&")
                id = id[0]
            }
            return id
        }

    }

    $(document).on("click", "a#rem-manage", function() {
        $(".man-close").click()
    });

    $(document).on("click", "div.whats-icon", function() {
        $(".share-close").click()
    });

    for (var x = 0; x < $(".creO-radio").length; x++) {
        $(".creO-rlabel").eq(x).attr("for", $(".creO-radio").eq(x).attr("id"))
    }

    $("#des_img,.logsec").on('change', function(e) {
        e.preventDefault()
        if ((this).files && (this).files[0]) {
            var reader = new FileReader();
            var x = document.createElement("IMG");
            var filename = (this).files[0].name;
            var btn = $(this).siblings("button")

            reader.onload = function(e) {

                x.setAttribute("src", e.target.result);
            };
            reader.readAsDataURL((this).files[0]);
            $(".des-name").text(filename)
            $(x).addClass("des_up_img")
            $('.des_icon').css("display", "block")
            $('.pro_icon').css("display", "block")
            if ($(".pro_icon").css("display") == "block") {
                $(".prof_rem").css("display", "none")
            }

            if ($(".pro_icon").is(':visible') || $(".des_icon").is(':visible')) {
                $(".sdes_img").css('display', 'none')
                $("a.pro_rem").css('display', 'none')
            }
            $(btn).empty()
            btn.append(x);

        }
    })

    if ($(".pro_icon").css("display") == "block") {
        $(".prof_rem").css("display", "none")
    }
    $("#cond_img").on('change', function() {
        if ((this).files && (this).files[0]) {
            var reader = new FileReader();
            var x = document.createElement("IMG");

            reader.onload = function(e) {

                x.setAttribute("src", e.target.result);
            };

            reader.readAsDataURL((this).files[0]);
            // $(".des-name").text(filename)
            $(x).addClass("cond_up_img")

            $(".cond-img").append('<i class="fal fa-times cond-i"></i>')
            $(".cond-img").append(x);
            $(".upl-sec").hide()
            upConPost()
        }
    })

    if (document.getElementById("organization_banner")) {
        document.getElementById("organization_banner").onchange = function() {
            document.getElementById("banner-img").submit();
        };

    }

    $(document).on("click", "i.cond-i", function() {
        $(".upl-sec").show()
        $(".cond-img").empty();
        upConPost()
    })

    $('.pro_icon').on('click', function() {

        $('#des_img').val('');
        if ($('#organization_id_number,#partner_contact_name').is(':visible')) {
            $(this).parent().siblings("#dropDes").children('button').empty()
            $(this).parent().siblings("#dropDes").children('button').append("<i class='fas fa-file-image fa-2x'></i>")
            $(this).siblings('span').text('Upload Business Logo')
        } else if ($('#signup').is(':visible')) {
            $(this).parent().siblings().children('button').children('img').removeClass('des_up_img')
            $(this).parent().siblings().children('button').children('img').addClass('placeImg')
            $(this).parent().siblings().children('button').children('img').attr('src', placeholderimg)
            $(this).siblings('span').text('Upload A Profile photo')
        } else if ($('#obituary_date_of_death').is(':visible')) {
            $(this).parent().siblings().children('button').children('img').removeClass('des_up_img')
            $(this).parent().siblings().children('button').children('img').addClass('placeImg')
            $(this).parent().siblings().children('button').children('img').attr('src', placeholderimg)
            $(this).siblings('span').text('Upload A Photo Of The Deceased')
        } else if ($('#obituary_date_of_birth').is(':visible')) {
            $(this).parent().siblings().children('button').children('img').removeClass('des_up_img')
            $(this).parent().siblings().children('button').children('img').addClass('placeImg')
            $(this).parent().siblings().children('button').children('img').attr('src', placeholderimg)
            $(this).siblings('span').text('Upload A Photo')
        } else {
            $(this).siblings().children('button').children('img').removeClass('des_up_img')
            $(this).siblings().children('button').children('img').attr('src', placeholderimg)
            $(this).siblings().children('button').children('img').addClass('placeImg')
            $(this).siblings('span').text('Upload A Profile photo')
        }
        $('.pro_icon').css('display', 'none')
    })
    $("#des_bg_img").on('change', function() {
        if ((this).files && (this).files[0]) {
            var reader = new FileReader();
            var x = document.createElement("IMG");
            var filename = (this).files[0].name;
            var btn = $(this).siblings("button")

            reader.onload = function(e) {

                x.setAttribute("src", e.target.result);
            };

            reader.readAsDataURL((this).files[0]);
            $("#des_bg_img .des-name").text(filename)
            $(x).addClass("des_up_img")
            $(btn).empty()
            btn.append(x);
        }
    })

    // if ($(".message").text() == "Message sent successfully" && $(".message").css("display") != "none") {
    //     // location.reload();
    //     grecaptcha.render('html_element', {
    //         'sitekey': '#{ENV['GOOGLE_RECAPTCHA']}',
    //     });
    // }
    $("#contact_message").val("")
    $("#contact_email").val("")
    $("#contact_full_name").val("")

    $(".post_obit").on('click', function() {
        // alert("D")
        var ob = ""
        localStorage.setItem("need_help", ob)
        localStorage.setItem("my_own", ob)
            // localStorage.setItem("current_step",1)
    })

    if ($(".alreadyDon").is(":visible")) {
        $(".charow").css("display", "none")
    }

    $("#need_help").on('click', function() {
        localStorage.setItem("my_own", JSON.stringify($("#creObit").html()))
        var help = (localStorage.getItem("need_help"))
            // console.log(help==''||)
        if (help == '' || help == '""') {
            var txt = $("#copyArea").clone()
            $("#creObit").html(txt)
                // localStorage.setItem("need_help", JSON.stringify($("#creObit").html()))
        } else {
            help = JSON.parse(help)
            $("#creObit").html(help)
        }
        $(".nt-box").css("display", "block")
    })

    $("#write_own").on('click', function() {
        localStorage.setItem("need_help", JSON.stringify($("#creObit").html()))
        if (localStorage.getItem("my_own") != '') {

            var own = JSON.parse(localStorage.getItem("my_own"))
        }
        if (own == '' || own == '""') {
            $("#creObit").empty()
        } else {
            $("#creObit").html(own)
        }
        $(".nt-box").css("display", "none")
    })

    $('.cObit-bk').on("click", function() {
        $("#obituary_next_step").val($('.cObit-bk').attr("value"))
        $(".edit_obituary").submit()
    })

    $(document).ready(function() {
        if ($('#need_help').is(':checked')) {
            $(".nt-box").css("display", "block")
            localStorage.setItem("my_own", JSON.stringify($("#creObit").html()))
            var help = (localStorage.getItem("need_help"))
                // console.log(help=='""')
            if (help == '' || help == '""') {
                var txt = $("#copyArea").clone()
                $("#creObit").html(txt)
                    // localStorage.setItem("need_help", JSON.stringify($("#creObit").html()))
            } else {
                help = JSON.parse(help)
                $("#creObit").html(help)
            }
        } else if ($('#write_own').is(':checked')) {

            if ($("#creObit").val() == '') {
                localStorage.setItem("need_help", JSON.stringify($("#creObit").html()))
                if (localStorage.getItem("my_own") != '') {

                    var own = JSON.parse(localStorage.getItem("my_own"))
                }
                if (own == '' || own == '""') {
                    $("#creObit").empty()
                } else {
                    $("#creObit").html(own)
                }
                $(".nt-box").css("display", "none")
            } else {

            }
        }
    })

    upConPost()

    $('#condolence_content').on("keyup keydown change", function() {
        upConPost()
    })

    function upConPost() {
        if ($('#condolence_content').val() == '' && $(".cond-img").is(':empty')) {
            $(".condo-post").attr('disabled', true)
        } else {
            $(".condo-post").attr('disabled', false)
        }
    }

    $(".forgotPas").on('click', function() {
        var close = $('#loginModal').children().children().children().children('.modalp-close')
        $(close).click();
    })

    $('#noDonation').on('click', function() {
        if ($(this).is(':checked')) {
            $("#allDonation").css("display", "none")
            $("#odoAmount").attr('required', false);
            $(".bentype").attr('required', false);
            $("#odoAmount").attr('value', "")
            $("#obituary_next_step").val(7)
            $('.nt-box_do').css('display', 'block')
        }

    })
    $('#yesDon').on('click', function() {
        $("#allDonation").css("display", "block")
        $('.nt-box_do').css('display', 'none')
        $("#odoAmount").attr('required', true);
        $(".bentype").attr('required', true);
        // $("#obituary_next_step").val(6)
        if ($('#charity').is(":checked")) {
            // $(".causes").css("display","block")
            if ($(".savedCharity").length > 0 || $("#st2-fun-home").children().length > 1) {
                $("#charities").css("display", "none")
                $(".charSearch").css("display", "none")
                $(".charow").css("display", "none")
                $(".donationAmount").removeClass("donattop")
            }
        } else {
            $(".causes").css("display", "none")
            $(".savedCharity").css("display", "none");
            $(".charow").css("display", "none")
        }
        if ($('#the_family').is(":checked")) {
            $(".nt-box_do.fam").css("display", "block")
        } else {
            $(".nt-box_do.fam").css("display", "none")
        }
        if ($("#obstr").val() === "true") {
            $("#obituary_next_step").val(7)
        } else {
            $("#obituary_next_step").val(6)
        }
        // $(".charSearch.scrollableList.charscroll").remove();

    })

    $('.bentype').on('click', function() {
        if ($(this).val() == 'charity') {
            // $(".causes").css('display','none')
            // alert($(this).val())
            $(".obitSavedHome").css("display", "none");
            $("#obituary_next_step").val(7)
            $(".donationAmount").addClass("donattop")
            $(".nt-box_do.fam").css("display", "none")
                // getCharities()
            if ($(".savedCharity").length > 0) {
                $(".savedCharity").css("display", "flex");
                $("#st2-fun-home").css("display", "none");
                $(".charSearch").css('display', 'none')
                $(".charow").css('display', 'none')
                $(".donationAmount").removeClass("donattop")
            } else {
                if ($("#st2-fun-home").children().length > 1) {
                    $("#st2-fun-home").css("display", "flex");
                    $("#search-char").css('display', 'none')
                    $(".charSearch").css('display', 'none')
                    $(".charow").css('display', 'none')
                    $(".donationAmount").removeClass("donattop")

                } else {
                    $("#search-char").css('display', 'block')
                    $(".charSearch").css('display', 'block')
                    getCharities()
                    $("#st2-fun-home").css("display", "none");
                    $(".charow").css('display', 'flex')
                    if ($(".charSearch").css("display") == "block") {
                        // $(".causes").css("display", "none")
                    }

                }

            }

        } else {
            $(".donationAmount").removeClass("donattop")
            $("#search-char").css('display', 'none')
            $(".charSearch").css('display', 'none')
            $(".charow").css('display', 'none')
            $("#search-char").val('')
            $(".savedCharity").css("display", "none");
            // $("#obituary_next_step").val(6)
            if ($("#obstr").val() === "true") {
                $("#obituary_next_step").val(7)
            } else {
                $("#obituary_next_step").val(6)
            }
            $(".savedCharity").css("display", "none");
            $(".obitSavedHome").css("display", "flex");
            $("#ben_uuid").val($('.obitSavedHome').attr("id"))
            $("#st2-fun-home").css("display", "none");
            $(".charities").css('display', 'none')

            if ($(this).val() == 'the_family') {
                $(".nt-box_do.fam").css("display", "block")
            } else {
                $(".nt-box_do.fam").css("display", "none")
            }

        }
    })

    $('.skip_team').on('click', function(e) {
        e.preventDefault()
        $("#organization_next_step").val(6)
        $('.edit_organization').submit()
    })

    if ($('.page-content.det').children('.trix-content').height() >= "385") {
        $('.page-content.det').children('.trix-content').addClass('ob_det')
        $('.read_more').css("display", "block")
    }
    // console.log($('.page-content.det').children('.trix-content').height())
    $('.read_more').on('click', function() {
        $('.read_less').css("display", "block")
        $('.read_more').css("display", "none")
        $('.page-content.det').children('.trix-content').removeClass('ob_det')
    })
    $('.read_less').on('click', function() {
        $('.read_less').css("display", "none")
        $('.read_more').css("display", "block")
        $('.page-content.det').children('.trix-content').addClass('ob_det')
    })
    $('.show-more').on('click', function() {
        if (window.location.href.indexOf("?per_page=") > -1) {
            var pg = window.location.href.split("per_page=")[1]
            pg = pg.split("#")[0]
            var tb = window.location.href.split("#")[1]
            if (tb != '') {
                tb = '#' + tb
            }
            p = parseInt(pg) + 10
            Turbolinks.visit('?per_page=' + p + tb)
        } else {
            Turbolinks.visit('?per_page=20')
        }
    })
    $('.show-less').on('click', function() {
        if (window.location.href.indexOf("?per_page=") > -1) {
            var pg = window.location.href.split("per_page=")[1]
            pg = pg.split("#")[0]
            var tb = window.location.href.split("#")[1]
            if (tb != '') {
                tb = '#' + tb
            }
            p = parseInt(pg) - 10
            if (p >= 10) {
                Turbolinks.visit('?per_page=' + p + tb)
            }
        }

    })

    var ob = window.location.href.split("obituaries/")[1];
    if (ob) {
        ob = ob.split('-')
        ob = ob[0]
    }

    $(".post_obit_step7").on('click', function() {
        $("#obituary_next_step").remove();
        // $('.edit_obituary').submit()  
    })

    $('.add_stripe').on('click', function(e) {

        if ($('#charity').is(':checked')) {
            // $("#obituary_next_step").val(7)
            $(".nt-box_do.fam").css("display", "none")
            if (($("#obituary_charity_id").val() == "" || $("#obituary_charity_id").val() == null) && $('#yesDon').is(':checked')) {
                e.preventDefault()
                alert("Please select a charity")
            } else {

                var x = parseFloat($('#odoAmount').val())
                if (x < 1) {
                    confirm("Donation amount must be at least $1")
                    e.preventDefault()
                } else if (x >= 1) {
                    $(".edit_obituary").submit()
                    $("#obituary_next_step").val(7)
                }
            }
        }

        if ($('#noDonation').is(':checked')) {
            $("#allDonation").css("display", "none")
            $("#odoAmount").attr('value', "")
            $(".bentype").attr('required', false);
            $("#odoAmount").attr('required', false);
            $("#obituary_next_step").val(7)
            $('.nt-box_do').css('display', 'block')
        } else if ($(".alreadyDon").is(":visible") || $("#the_funeral_home").is(':checked') || $("#the_family").is(':checked')) {
            $(".bentype").attr('required', true);
            var x = parseFloat($('#odoAmount').val())
            if (x < 1) {
                confirm("Donation amount must be at least $1")
                e.preventDefault()
            } else if (x >= 1) {
                if ($("#obstr").val() === "true") {
                    $("#obituary_next_step").val(7)
                } else {
                    $("#obituary_next_step").val(6)
                }
                // $("#obituary_next_step").val(6)
            }

        }
    })

    function getCharities() {
        $.getJSON("/api/v1/charities/causes", function(data) {
            // console.log(data)
            $(".causes").html('');
            $(".causes").append('<option value=""> Filter by Cause</option>')
            $.each(data, function(key, value) {
                $(".causes").append('<option class="copt" value=' + key + '>' + value + '</option>')
            })
            if ($('.causes').parent().children(".scrollableList").length < 1) {
                $(function() {
                    $('.causes').each(function(index, element) {

                        $(this).parent()
                            .after()
                            .append("<div class=' charSearch scrollableList p-0'><div id='field-price' class='selectedOption creOb-input ob-input causes-filter '></div><ul class='charul'></ul></div>");

                        $(element).each(function(idx, elm) {
                            $('option', elm).each(function(id, el) {
                                $('.scrollableList ul:last').append('<li class="copt" ' + ' value=' + $(el).val() + '>' + el.text + '</li>');
                            });
                            $('.scrollableList ul').hide();
                        });

                        $('.scrollableList:last').children('div.selectedOption').text("Filter by Cause").append("<i class='fal fa-angle-down pdown'></i>");

                    });

                    // if($('.causes').is(":visible")){

                    $('.selectedOption').on('click', function() {
                        $(this).next('ul').slideToggle(200);
                        $('.selectedOption').not(this).next('ul').hide();
                    });

                    $('.scrollableList ul li').on('click', function() {
                        var selectedLI = $(this).text();
                        $(this).parent().prev('.selectedOption').text(selectedLI).append("<i class='fal fa-angle-down pdown'></i>");
                        $(this).parent('ul').hide();
                        causeopt = $(this).attr('value')

                    });

                    $('.scrollableList').show();
                    $("#search-char").show();
                    $('.causes').hide();

                    // }

                });
            } else {}
        })
    }

    if ($(".causes").is(":visible") && $('#charity').is(":checked")) {
        $(".nt-box_do.fam").css("display", "none")
        getCharities()
    }

    $('.causes').on('change', function() {
        // alert( this.value );
        causeopt = this.value
    });
    $(document).ready(function() {
        if ($('#noDonation').is(":visible")) {

            if ($('#noDonation').is(':checked')) {
                $("#allDonation").css("display", "none")
                $("#odoAmount").attr('value', "")
                $(".bentype").attr('required', false);
                $("#odoAmount").attr('required', false);
                $("#obituary_next_step").val(7)
                $('.nt-box_do').css('display', 'block')
            } else {
                $("#allDonation").css("display", "block")
                $("#odoAmount").attr('required', true);
                // $("#obituary_next_step").val(6)
                if ($("#obstr").val() === "true") {
                    $("#obituary_next_step").val(7)
                } else {
                    $("#obituary_next_step").val(6)
                }
                $('.nt-box_do').css('display', 'none')
                $(".bentype").attr('required', true);
                // $(".charSearch.scrollableList.charscroll").remove();

            }
        }

        if ($('.bentype').is(":visible")) {
            if ($('#charity').is(":checked")) {
                // $(".causes").css('display','none')
                $(".donationAmount").addClass("donattop")
                $(".obitSavedHome").css("display", "none");
                $("#obituary_next_step").val(7)
                $(".nt-box_do.fam").css("display", "none")

                if ($(".savedCharity").length > 0) {
                    $(".savedCharity").css("display", "flex");
                    $("#st2-fun-home").css("display", "none");
                    $(".charSearch").css('display', 'none')
                    $(".charow").css('display', 'none')
                    $(".donationAmount").removeClass("donattop")
                } else {
                    if ($("#st2-fun-home").children().length > 1) {
                        $("#st2-fun-home").css("display", "flex");
                        $("#search-char").css('display', 'none')
                        $(".charSearch").css('display', 'none')
                        $(".charow").css('display', 'none')
                        $(".donationAmount").removeClass("donattop")

                    } else {
                        $("#search-char").css('display', 'block')
                        $("#st2-fun-home").css("display", "none");
                        $(".charSearch").css('display', 'block')
                        $(".charow").css('display', 'flex')
                        $("#search-char").css('display', 'block')
                        if ($(".charSearch").css("display") == "block") {
                            $(".causes").css("display", "none")
                        }

                    }

                }

            } else {
                $(".donationAmount").removeClass("donattop")
                $("#search-char").css('display', 'none')

                // $("#obituary_next_step").val(6)
                if ($("#obstr").val() === "true") {
                    $("#obituary_next_step").val(7)
                } else {
                    $("#obituary_next_step").val(6)
                }
                $(".obitSavedHome").css("display", "flex");
                $(".savedCharity").css("display", "none");
                $("#st2-fun-home").css("display", "none");
                $(".charSearch").css('display', 'none')
                $(".charow").css('display', 'none')
                $(".charities").css('display', 'none')
                $("#ben_uuid").val($('.obitSavedHome').attr("id"))
                if ($('#the_family').is(":checked")) {
                    $(".nt-box_do.fam").css("display", "block")
                } else {
                    $(".nt-box_do.fam").css("display", "none")
                }

            }

        }
    })

    $(".des-name").on("click", function() {
        $("#des_img").trigger("click")
            // alert("hi")
    })
    var edhome = false;
    var isTyping = $('#search-fun').val();

    $("#search-fun").on("keyup keydown change", function() {
        var msg = $('#fun-homes');
        if ($('#search-fun').val() != '' && $('#search-fun').is(':visible')) {

            isTyping = $('#search-fun').val();
        } else {
            isTyping = ""
        }
        // console.log(isTyping)
        $.ajax({
            type: 'GET',
            url: "/api/v1/funeral-homes?keywords=" + isTyping,
            cache: false,
            success: function(response) {

                if (response.length > 0 && isTyping != '') {

                    $("#fun-homes").empty()
                    for (var i = 0; i < response.length; ++i) {
                        var divr = document.createElement("div");
                        var divh = document.createElement("div");
                        var divb = document.createElement("div");
                        var h6w = document.createElement("h6");
                        var h6r = document.createElement("h6");
                        var h6s = document.createElement("h6");
                        var btnr = document.createElement("button");
                        var pSt = document.createElement("p");
                        $(divr).addClass("rhome");
                        $(divr).addClass("row");
                        $(btnr).addClass("btnr");
                        $(divb).addClass("col-3");
                        $(divb).addClass("text-right");
                        $(divh).addClass("col-9");
                        $(btnr).attr("value", response[i].id);
                        $(btnr).attr("type", "button");
                        h6w.innerHTML = response[i].name;
                        h6r.innerHTML = response[i].address;
                        h6s.innerHTML = response[i].city + ", " + response[i].city_state + " " + response[i].zipcode;
                        btnr.innerHTML = "Select";
                        divh.append(h6w);
                        divh.append(h6r);
                        divh.append(h6s);

                        if (response[i].connected_to_stripe == false && $('#yesDon').is(":visible")) {
                            $(divr).addClass("noStripe")
                            pSt.innerHTML = "(This funeral home is not set up to receive donations)"
                            divh.append(pSt)
                        } else {
                            // $(divr).removeClass("noStripe")
                            // pSt.innerHTML = ""
                            divb.append(btnr);
                        }
                        divr.append(divh);
                        divr.append(divb);
                        $("#fun-homes").append(divr);
                        $("#fun-homes").css("display", "block");
                    }
                    if (edhome == true) {
                        var homes = $("#fun-homes").children('.rhome')
                        for (h = 0; h < homes.length; h++) {
                            if ($(homes).eq(h).children('.col-9').children('h5').text() == $('#saved_home').attr('value')) {
                                $(homes).eq(h).children('.col-3').children('.btnr').click()
                            }
                        }

                    }
                } else if (response.length == 0 && isTyping != "") {

                    $("#fun-homes").empty()
                    var divr = document.createElement("div");
                    var divh = document.createElement("div");
                    var divb = document.createElement("div");
                    var divt = document.createElement("div");
                    var p = document.createElement("p");
                    var h6r = document.createElement("h6");
                    var inp = document.createElement("input");
                    var btnr = document.createElement("button");
                    $(divr).addClass("rhome");
                    $(divr).addClass("rhome2");
                    $(divr).addClass("row");
                    $(btnr).addClass("btnr2");
                    $(divb).addClass("col-3");
                    $(divb).addClass("text-right");
                    $(divh).addClass("col-9");
                    $(divt).addClass("rhome");
                    $(btnr).attr("value", "");
                    $(btnr).attr("type", "button");
                    $(inp).addClass("ob-input creOb-input direc")
                    $(p).addClass('direerror')
                    inp.setAttribute('type', 'email');
                    h6r.innerHTML = '"' + isTyping + '"' + " is not on Obit";
                    btnr.innerHTML = "Send Invite";
                    p.innerHTML = "Please enter a valid email address"
                    $(inp).attr("placeholder", "Funeral Directors Email*")
                    divh.append(h6r);
                    divh.append(inp);
                    divh.append(p)
                    $(btnr).css("width", "135px")
                    divr.append(divh);
                    divb.append(btnr);
                    divr.append(divb);
                    // $("#fun-homes").append(divt);
                    $("#fun-homes").append(divr);
                    $("#fun-homes").css("display", "block");
                } else {
                    $("#fun-homes").css("display", "none");
                }
            }
        });
    });

    $(document).on("click keyup keydown change select", "#search-char, .copt", function() {

        var msg = $('#charities');
        if ($('#search-char').val() != '' && $('#search-char').is(':visible')) {

            isTyping = $('#search-char').val();
        } else {
            isTyping = ""
        }
        cause = causeopt
        if (cause == undefined || cause == null) {
            cause = ""
        }
        // console.log(isTyping) 
        if (isTyping.length >= 3) {

            $.ajax({
                type: 'GET',
                url: "/api/v1/charities?keywords=" + isTyping + '&cause_id=' + cause,
                cache: false,

                success: function(response) {

                    if (response != null) {
                        // console.log(response)
                        if (response.length > 0 && (isTyping != '' || cause != '')) {

                            $("#charities").empty()
                            for (var i = 0; i < response.length; ++i) {
                                var divr = document.createElement("div");
                                var divh = document.createElement("div");
                                var divb = document.createElement("div");
                                var divw = document.createElement("div");
                                var h5r = document.createElement("h6");
                                var im = document.createElement("img");
                                var h6s = document.createElement("h6");
                                var btnr = document.createElement("button");
                                var pSt = document.createElement("p");
                                $(divr).addClass("rhome");
                                $(divr).addClass("row");
                                $(btnr).addClass("btnr");
                                $(divb).addClass("col-3");
                                $(divb).addClass("text-right");
                                $(divh).addClass("col-9");
                                $(btnr).attr("value", response[i].id);
                                $(btnr).attr("type", "button");
                                $(divw).addClass("mt-2 ml-3 d-inline-block");
                                $(divw).css("position", "absolute")
                                    // $(divr).addClass("noStripe")
                                h5r.innerHTML = response[i].name;
                                im.src = response[i].logo_url
                                $(im).css('width', '71px')
                                h6s.innerHTML = response[i].city + " " + response[i].region + " " + response[i].country;
                                btnr.innerHTML = "Select";
                                divh.append(im)
                                divw.append(h5r);
                                // divh.append(h6r);
                                divw.append(h6s);
                                divh.append(divw)

                                divb.append(btnr);
                                divr.append(divh);
                                divr.append(divb);
                                $("#charities").append(divr);
                                $("#charities").css("display", "block");
                                $(".charSearch.scrollableList.charscroll").css("height", "40px")
                                if ($(".savedCharity").length > 0 || $("#st2-fun-home").children().length > 1) {
                                    $("#charities").css("display", "none")
                                    $(".donationAmount").removeClass("donattop")
                                } else {
                                    $("#charities").css("display", "block");
                                }

                                if ($("#st2-fun-home").css("display") == "flex") {
                                    $("#charities").css("display", "none")
                                    $(".donationAmount").removeClass("donattop")
                                } else {
                                    $("#charities").css("display", "block");
                                }
                            }
                            if (edhome == true) {
                                var homes = $("#charities").children('.rhome')
                                for (h = 0; h < homes.length; h++) {
                                    if ($(homes).eq(h).children('.col-9').children('h5').text() == $('#saved_home').attr('value')) {
                                        $(homes).eq(h).children('.col-3').children('.btnr').click()
                                    }
                                }

                            }
                        } else if (response.length == 0 && isTyping != "") {

                            $("#charities").empty()
                            var divr = document.createElement("div");
                            var divh = document.createElement("div");
                            var divb = document.createElement("div");
                            var divt = document.createElement("div");
                            var h5r = document.createElement("h5");
                            var h6r = document.createElement("h6");
                            var btnr = document.createElement("button");
                            $(divr).addClass("rhome");
                            $(divr).addClass("rhome2");
                            $(divr).addClass("row");
                            $(btnr).addClass("btnr2");
                            $(divb).addClass("col-3");
                            $(divb).addClass("text-right");
                            $(divh).addClass("col-9");
                            $(divt).addClass("rhome");
                            $(btnr).attr("value", "");
                            $(btnr).attr("type", "button");
                            h6r.innerHTML = "We’re sorry, No charity was found";
                            h5r.innerHTML = "We recommend changing this donation to benefit another beneficiary";
                            // btnr.innerHTML = "Select";
                            divt.append(h6r);
                            $("#charities").append(divt);
                            if ($('#odoAmount').is(':visible')) {

                                divh.append(h5r);
                                // divb.append(btnr);
                                divr.append(divh);
                                divr.append(divb);
                                $("#charities").append(divr);
                            }
                            $("#charities").css("display", "block");
                            if ($(".savedCharity").length > 0 || $("#st2-fun-home").children().length > 1) {
                                $("#charities").css("display", "none")
                                $(".donationAmount").removeClass("donattop")
                            } else {
                                $("#charities").css("display", "block");
                            }

                            if ($("#st2-fun-home").css("display") == "flex") {
                                $("#charities").css("display", "none")
                                $(".donationAmount").removeClass("donattop")
                            } else {
                                $("#charities").css("display", "block");
                            }
                            $(".charSearch.scrollableList.charscroll").css("height", "40px")
                        } else {
                            $("#charities").css("display", "none");
                        }
                    }

                }
            });
        }
    });

    $(".direc").on("keyup keydown change click", function() {
        $("#fun-homes").css("display", "block")
    })

    $(document).on("click", "div.rhome", function() {
        if ($(this).children().children('h6').text().includes("is not on Obit")) {
            if ($(".direc").val() != "" && $('.direerror').css("display") == 'none') {
                $("#obituary_invite_home_email").val($(".direc").val())
                $("#fun-homes").css("display", "none")

                $(".funSearch").css("display", "none")
                $('#search-fun').val("");
                isTyping = ''
                $(".funSearch").removeClass("des-search")
                $(".invi_email").text(($(".direc").val()))
                $("#invite_for").css("display", "flex")
            }
        } else {
            $("#ben_uuid").val($(this).children('.col-3').children('.btnr').val())
            $("#fun-homes").css("display", "none")
            $("#charities").css("display", "none")
            $("#search-char").css("display", "none")
            $(".charSearch").css("display", "none")
            $(".charow").css('display', 'none')

            $("#sel-fun-home").css("display", "flex")
            $("#st2-fun-home").css("display", "flex");
            $("#sel-fun-home").append("<div class='col-1 text-center'><i class='fal fa-times cancel-home'></i></div>");

            $("#sel-fun-home").append($(this).children('.col-9'))
            $("#st2-fun-home").append($(this).children('.col-9'))
            $(".donationAmount").removeClass("donattop")
            $("#st2-fun-home").children(".col-9").addClass("p-0")
            $("#st2-fun-home").children().children("h6").addClass("mb-0")
            $(".funSearch").css("display", "none")
            $(".funSearch").removeClass("des-search")
            $('#search-fun').val("");
            $("#search-char").val("")
            $("#obituary_organization_id").val($(this).children('.col-3').children('.btnr').val())
            $("#obituary_charity_id").val($(this).children('.col-3').children('.btnr').val())
            isTyping = ''
                // console.log($(this).children())
        }
    });

    if ($(".homeSearch").css("display") == "block") {
        $(".funSearch").css("display", "block");
    } else {
        $(".funSearch").css("display", "none");
    }

    $(document).on("click", "i.cancel-home", function() {
        $("#sel-fun-home").css("display", "none");
        $("#st2-fun-home").css("display", "none");
        $(".funSearch").css("display", "block");
        $("#search-char").css("display", "block");
        $(".charSearch").css("display", "block");
        $(".charow").css('display', 'flex')

        $('#search-fun').val("");
        $("#sel-fun-home").empty();
        $("#st2-fun-home").children('.col-9').remove()
        $(".funSearch").addClass("des-search")
        $("#obituary_organization_id").val("")
        $(".savedCharity").css("display", "none");
        $("#obituary_charity_id").val("")
        $(".donationAmount").addClass("donattop")
    });

    $(document).on("click", "i.cancel-invite", function() {
        $(".invi_email").text('')
        $("#obituary_invite_home_email").val('')
        $("#invite_for").css("display", "none")
        $(".funSearch").css("display", "block");
        $('#search-fun').val("");
        $("#sel-fun-home").empty();
        $("#st2-fun-home").children('.col-9').remove()
        $(".funSearch").addClass("des-search")
        $("#obituary_organization_id").val("")
        $(".donationAmount").addClass("donattop")
    });

    $(document).on("click", "i.cancel-home-saved", function() {
        $(".obitSavedHome").css("display", "none");
        $(".obitHome").css("display", "block");
        $(".funSearch").css("display", "block");
        $('#search-fun').val("");
        $(".funSearch").addClass("des-search")
        $("#obituary_organization_id").val("")
        $(".obitSavedHome").empty();
        $(".obitSavedHome").attr("id", "");
    });

    $(".d-tip").on('click', function() {
        var tot = 0
        tot += parseInt($(this).val())
        if (($("#doAmount").val()) == "") {
            tot += 0
        }
        if (parseInt($("#doAmount").val()) >= 0) {
            tot += parseInt($("#doAmount").val())
        }
        $("#donation-total").text(tot)
        $("#tip_amount").val(parseInt($(this).val()))
        $("#customTip").val("")
    })

    if ($(".d-tip").eq(1).is(':checked')) {
        var tot = 0
        var defTip = $(".d-tip").eq(1).val()
        tot += parseInt(defTip)
        if (($("#doAmount").val()) == "") {
            tot += 0
        }
        if (parseInt($("#doAmount").val()) >= 0) {
            tot += parseInt($("#doAmount").val())
        }
        $("#donation-total").text(tot)
        $("#tip_amount").val(parseInt(defTip))
        $("#customTip").val("")
            // console.log($(".d-tip").eq(1))
    }

    $('#payeeDet').on('click', function() {
        if ($(this).is(':checked')) {
            $('.payeeAdd').val($('.funAdd').val())
            $('.payeezip').val($('.funzip').val())
            $('.payeeCi').val($('.funcity').val())
            $('.payeestate').val($('.funstate').val())
            $('.payeecountr').val($('.funcountr').val())
            $('.payeephone').val($('.funphone').val())
        }

    })

    if ($('#payeeDet').is(":visible")) {
        if ($('#payeeDet').get(0).checked) {
            $('.payeeAdd').val($('.funAdd').val())
            $('.payeezip').val($('.funzip').val())
            $('.payeeCi').val($('.funcity').val())
            $('.payeestate').val($('.funstate').val())
            $('.payeecountr').val($('.funcountr').val())
            $('.payeephone').val($('.funphone').val())
        }

    }

    $(function() {
        $('.actionSelect').each(function(index, element) {
            // console.log("l")
            $(this).parent()
                .after()
                .append("<div class='scrollableList' style='background-color:transparent !important'><div id='pending_action' class='selectedOption creOb-input ob-input' style='border-bottom:none !important;text-align:center;margin: 0px;font-size:1rem;line-height: 3;background:transparent'></div><ul style='text-align:center;max-width: 16%;height: auto;position:absolute'></ul></div>");

            $(element).each(function(idx, elm) {
                $('option', elm).each(function(id, el) {
                    $('.scrollableList ul:last').append('<li style="font-size:1rem;text-align:center;cursor:pointer" ' + ' value=' + $(el).val() + '>' + el.text + '</li>');
                });
                $('.scrollableList ul').hide();
                if ($('.obReview').eq(index).val() == "Clear") {
                    // console.log(index)
                    $('.scrollableList:last').children('div.selectedOption').eq(index).text("Cleared");
                } else {
                    $('.scrollableList:last').children('div.selectedOption').text("Pending Action");
                }
                $('.scrollableList:last').children('div.selectedOption').append('<i class="fal fa-chevron-down" style="margin-left: 15px;"></i>')
            });
        });

        $('.actionSelect').siblings('.scrollableList').children('.selectedOption').on('click', function() {
            $(this).next('ul').slideToggle(200);
            $('.selectedOption').not(this).next('ul').hide();
        });

        $('.scrollableList ul li').on('click', function() {
            var selectedLI = $(this).text();
            $(this).parent().prev('.selectedOption').text(selectedLI);
            $(this).parent('ul').hide();

            if (selectedLI) {

            } else {

            }

        });

        $('.scrollableList').show();
        $('.actionSelect').hide();
    });

    if ($('.edit_obituary').is(":visible")) {
        var st = $('#current_step').val()
        localStorage.setItem('current_step', st)
    }
    $('.obit_step_bar').on('click', function(e) {
        var cur = localStorage.getItem('current_step')
        cur = parseInt(cur)
        var step = $(this).attr('value')
        var last_step = $("#last_step").val()
            // console.log(step -1, cur)
            // console.log(cur,step,last_step)
        if (step > cur) {
            if (step <= last_step) {
                $("#obituary_next_step").val(step)
                $('.edit_obituary').submit()
            }
        } else {
            $("#obituary_next_step").val(step)
            $('.edit_obituary').submit()
        }
    })

    $('.copy-share').on('click', function() {
        var copyText = document.getElementById("share-link");
        copyText.select();
        copyText.setSelectionRange(0, 99999)
        document.execCommand("copy");
        // alert("Link Copied" );
    })

    if ($(".savedCharity").length > 0 || $("#st2-fun-home").children().length > 1) {
        $("#charities").css("display", "none")
        $(".charSearch").css("display", "none")
        $(".donationAmount").removeClass("donattop")
    }

    if ($("#st2-fun-home").css("display") == "flex") {
        $("#charities").css("display", "none")
        $(".donationAmount").removeClass("donattop")
    }

})

const signUpModalFx = (open, subclass, num, nal) => {
    let close = document.querySelector(".modal-close" + nal)
    let modal = document.querySelector(".modal-container" + subclass)
        // console.log(open,subclass, num, close, modal)

    if (open) {
        open.addEventListener("click", (e) => {
            e.preventDefault()
            if (!modal.classList.contains("open")) {
                modal.classList.add("open")
                modal.classList.remove("close")
            } else {
                modal.classList.add("close")
                modal.classList.remove("open")
            }
        })

        close.addEventListener("click", () => {
            modal.classList.add("close")
            modal.classList.remove("open")
        })
    }

}

const navigationFx = () => {
    let url = window.location.href.split("#")[1]
    let navBtn = document.querySelectorAll(".sub-nav_link")
    let contents = document.querySelectorAll(".page-content")

    if (url) {
        contents.forEach(content => {
            if (content.getAttribute("data-name") == url) {
                content.classList.remove("content-hidden")
            } else if (!content.classList.contains("content-hidden")) {
                content.classList.add("content-hidden")
            }
        })
    }
    // console.log(window.location.pathname , "pathname this")
    navBtn.forEach(button => {
        // console.log(button)
        if (window.location.pathname === button.getAttribute("href") && !url) {

            button.classList.add("active")
        } else {
            if (url) {
                if (button.getAttribute("href").split("#")[1] === url) {
                    button.classList.add("active")
                        // console.log("added")
                } else {
                    button.classList.remove("active")
                }
            }
        }
    });
}

// let dropdownFx = (element )=>{
//     let content = element.nextElementSibling
//     let chevron = element.children[1]
//     // console.log(chevron)

// if(element){
//     element.addEventListener("click",(e)=>{
//         if(content.classList.contains("content-hidden")){
//             content.classList.remove("content-hidden")
//             chevron.style.transform = "rotate(0deg)"
//         }else{
//             content.classList.add("content-hidden")
//             chevron.style.transform = "rotate(180deg)"
//         }
//     })
// }  
// }

let dropdownFx = (element) => {
    let content = element.nextElementSibling;
    let chevron = element.children[1];
    // console.log(chevron)

    if (element) {
        element.addEventListener("click", (e) => {

            if (content.classList.contains("content-hidden")) {
                let serv = document.querySelectorAll(".drop-down-content")
                let drop = document.querySelectorAll(".drop-down-chevron")
                serv.forEach(ele => {
                    if (ele.classList.contains("content-hidden") == false) {
                        ele.classList.add("content-hidden")
                    }
                });
                drop.forEach(che => {
                    var sib = $(che).parent().siblings(".drop-down-content")
                    sib = sib[0]
                        //console.log(sib.classList.contains("content-hidden"))
                    if (sib.classList.contains("content-hidden")) {
                        che.style.transform = "rotate(180deg)"
                    }
                });
                content.classList.remove("content-hidden")
                chevron.style.transform = "rotate(0deg)"

            } else {
                content.classList.add("content-hidden")
                chevron.style.transform = "rotate(180deg)"
            }
        })
    }
}

let popup = () => {
    let popupBtn = document.querySelectorAll(".popup")

    popupBtn.forEach((btn) => {
        let popup = document.querySelector(`[data-popup="${btn.getAttribute("id")}"]`)

        btn.addEventListener("click", (e) => {
            popup.style.top = (btn.getBoundingClientRect().top + 20) + "px"
            if (popup.classList.contains("active")) {
                popup.style.top = (btn.getBoundingClientRect().top + 20) + "px"
                popup.classList.remove("active")
                btn.style.color = "inherit"
                btn.style.zIndex = "initial"
                setTimeout(() => {
                    popup.style.display = "none"
                }, 200)
            } else {
                popup.style.display = "block"
                btn.style.zIndex = "100"
                btn.style.color = "#169343"
                    //   console.log(btn.style) // = "100"
                setTimeout(() => {
                    popup.classList.add("active")
                    popup.style.top = "65px"
                }, 200)

            }
        })
    })
}

if ($("#dropDes").is(":visible")) {
    let dropArea = document.getElementById('dropDes')

    dropArea.addEventListener('dragenter', handlerFunction, false)
    dropArea.addEventListener('dragleave', handlerFunction, false)
    dropArea.addEventListener('dragover', handlerFunction, false)
    dropArea.addEventListener('drop', handlerFunction, false)
    dropArea.addEventListener('drop', handleDrop, false)
}
if ($("#dropBgDes").is(":visible")) {
    let dropArea = document.getElementById('dropBgDes')
    dropArea.addEventListener('dragenter', handlerFunction, false)
    dropArea.addEventListener('dragleave', handlerFunction, false)
    dropArea.addEventListener('dragover', handlerFunction, false)
    dropArea.addEventListener('drop', handlerFunction, false)
    dropArea.addEventListener('drop', handleDrop, false)
}

function handleDrop(e) {
    let dt = e.dataTransfer
    let files = dt.files

    handleFiles(files)
}

function handleFiles(files) {
    ([...files]).forEach(uploadFile)
}

function uploadFile(file) {
    let url = 'YOUR URL HERE'
    let formData = new FormData()

    formData.append('file', file)

    fetch(url, {
            method: 'POST',
            body: formData
        })
        .then(() => {
            /* Done. Inform the user */
        })
        .catch(() => {
            /* Error. Inform the user */
        })
}

const tabsFx = () => {
    let url = window.location.href.split("#")[1]
    let tabsBtn = document.querySelectorAll(".tab-nav a")
    let tabsContent = document.querySelectorAll(".tab-content")

    if (url) {
        tabsContent.forEach(content => {
            if (content.getAttribute("data-name") == url) {
                content.classList.remove("content-hidden")
            } else if (!content.classList.contains("content-hidden")) {
                content.classList.add("content-hidden")
            }
        })
    }

    tabsBtn.forEach(button => {
        // console.log(button)
        if (window.location.pathname === button.getAttribute("href") && !url) {
            // console.log(button.getAttribute("href"))
            // console.log("this is button " +  button)
            button.classList.add("active")
        } else {
            if (url) {
                if (button.getAttribute("href").split("#")[1] === url) {
                    button.classList.add("active")
                        // console.log("added")
                } else {
                    button.classList.remove("active")
                }
            }
        }
    });

}

function checkPasswordMatch() {
    var password = $(".pass").val();
    var confirmPassword = $(".conPass").val();

    if (password != confirmPassword) {
        $(".passError").css("display", "block");
        $(".button").attr("disabled", true);
    } else {
        $(".passError").css("display", "none")
        $(".button").attr("disabled", false);
        if ($("#user_email").val() != "" && $("#user_password").val() != "" && $("#user_password").val().length >= 8 && $("#user_password_confirmation").val() != "" && $("#user_password_confirmation").val().length >= 8) {
            $(".signUpBtn").attr("disabled", false)
        } else {
            $(".signUpBtn").attr("disabled", "disabled")
        }
    }
}